@import "common/_variables";
@import "common/_mixins";

.si-piechart-container {
  max-width: 963px;
  margin: 0 auto;
  padding: 0px 20px;

  @media screen and (max-width: $media-mobile-sm-min) {
    padding: 0 15px;
  }

  .si-header-wrap {
    @include flexbox;
    justify-content: space-between;
    align-items: center;

    & span {
      font-size: 34px;
      font-family: $medium_font-family;
      font-weight: $medium_font-weight;
      color: $client-primary-color;
      text-transform: uppercase;

      @media screen and (max-width: $media-desktop-min) {
        font-size: 23px;
      }

      @media screen and (max-width: $media-mobile-sm-min) {
        font-size: 20px;
      }
    }

    & a {
      font-size: 18px;
      font-family: $regular_font-family;
      font-weight: $regular_font-weight;
      text-transform: capitalize;
      color: $client-primary-color;
      position: relative;
      padding-right: 23px;

      @media screen and (max-width: $media-desktop-min) {
        font-size: 12px;
        padding-right: 15px;
      }

      &::after {
        content: "\e900";
        font-family: "dataviz-icon-fonts" !important;
        position: absolute;
        font-size: 12px;
        right: 0px;
        top: 50%;
        @include transform(translateY(-50%));
      }
    }
  }

  .si-section-data {
    position: relative;
    margin-top: 20px;
  }

  .si-chartBox {
    position: absolute;
    padding: 20px 30px;
    left: 0%;
    top: 0;
    right: 0;
    width: calc(100% - 51%);
    margin: auto;
    height: 100%;

    @media screen and (max-width: $media-desktop-min) {
      position: relative;
      width: 100%;
      padding: 0px;
    }

    .si-head {
      display: block;
      text-align: center;
    }
  }

  .si-chart {
    position: relative;
    margin: 23px auto 0px;
    width: 334px;
    height: 221px;
    @include animation("zoomIn 1.5s ease-in-out");

    @media screen and (max-width: $media-desktop-min) {
      width: 180px;
      height: 120px;
    }

    @media screen and (max-width: $media-mobile-max) {
      width: 334px;
      height: 221px;
    }

    @media screen and (max-width: $media-mobile-min) {
      width: 275px;
      height: 180px;
    }

    &::after {
      content: "";
      position: absolute;
    }

    .si-ground {
      background: url(../images/Ground.svg);
      background-size: cover;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
    }

    .si-winbox {
      margin: auto;
      position: absolute;
      top: 0;
      will-change: transform;
      width: 100%;
      height: 100%;
    }

    .si-winBoxp {
      width: calc(100% - 19%);
      height: calc(100% - 38%);
      position: absolute;
      top: 5px;
      left: 0;
      right: 0;
      margin: auto;
      @include transform(rotateX(14deg) skew(0deg));

      overflow: hidden;
      @include border-radius(50%);

      svg {
        @include transform(rotate(90deg) scale(2));

        circle {
          fill: transparent;

          stroke-width: 99.2%;

          @include transition(stroke-dasharray, 0.3s, ease);

          &.si-circleWin {
            stroke: rgba($pie-color, 0.65);
            stroke-dasharray: 337 674;
            @include animation("win 3s ease-in-out");
          }

          &.si-circleTie {
            stroke: rgba($pie-color, 0.45);
            stroke-dasharray: 60 674;
            @include animation("tie 3s ease-in-out");
          }
        }
      }
    }
  }

  .si-stats {
    @include flexbox;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: $media-mobile-max) {
      padding-bottom: 16px;
      border-bottom: 1px solid rgba($client-primary-color, 0.5);
    }

    &Left,
    &Right {
      flex: 1;
      @include flexbox;
      align-items: center;
      justify-content: center;
      padding: 0px 5px;
    }

    .si-statsnum {
      color: $client-primary-color;

      margin-left: 5px;
    }

    .si-iconbox {
      flex: 0 0 50px;
      width: 50px;
      position: relative;
      text-align: center;

      @media screen and (max-width: $media-desktop-min) {
        flex: 0 0 37px;
        width: 37px;
      }

      &::after {
        content: "";
        font-family: "dataviz-icon-fonts" !important;
        font-size: 42px;
        color: $client-secondary-color;

        @media screen and (max-width: $media-desktop-min) {
          font-size: 37px;
        }
      }
    }

    &Left {
      border-right: 1px solid rgba($gray, 0.5);

      .si-iconbox {
        &::after {
          content: "\e910";
        }
      }
    }

    &Right {
      .si-iconbox {
        &::after {
          content: "\e911";
        }
      }
    }

    .si-num {
      font-size: 45px;
      font-family: $medium_font-family;
      font-weight: $medium_font-weight;
      line-height: 1;

      @media screen and (max-width: $media-desktop-min) {
        font-size: 26px;
      }

      @media screen and (max-width: $media-mobile-sm-min) {
        font-size: 35px;
      }
    }

    .si-text {
      font-size: 12px;
      font-family: $medium_font-family;
      font-weight: $medium_font-weight;
      text-transform: uppercase;
      line-height: 1;

      @media screen and (max-width: $media-desktop-min) {
        font-size: 10px;
      }

      @media screen and (max-width: $media-mobile-max) {
        font-size: 12px;
      }

      @media screen and (max-width: $media-mobile-sm-min) {
        font-size: 10px;
      }
    }
  }

  .si-winlossBox {
    @include flexbox;
    justify-content: space-between;
    align-items: center;
    margin: 0px 0px 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba($client-primary-color, 0.5);

    @media screen and (max-width: $media-desktop-min) {
      margin: 0px 0px 10px;
    }

    @media screen and (max-width: $media-mobile-max) {
      margin-bottom: 16px;
    }

    .si-win,
    .si-lost,
    .si-tie {
      position: relative;
      padding-left: 30px;
      height: 23px;
      @include flexbox;
      justify-content: center;
      align-items: center;

      @media screen and (max-width: $media-mobile-min) {
        padding-left: 20px;
      }

      .si-num {
        font-size: 23px;
        font-family: $bold_font-family;
        font-weight: $bold_font-weight;
        margin: 0px 5px 0px 5px;
        line-height: 1;
        flex: 0 0 23px;
        text-align: center;
        color: $client-primary-color;

        @media screen and (max-width: $media-mobile-min) {
          font-size: 20px;
        }
      }

      .si-text {
        font-size: 15px;
        font-family: $regular_font-family;
        font-weight: $regular_font-weight;
        text-transform: uppercase;
        color: $client-primary-color;
      }

      &::after {
        content: "";
        position: absolute;
        left: 0px;
        top: 0;
        width: 26px;
        height: 23px;
        background-repeat: no-repeat;
        background-size: cover;
        @include border-radius(4px);

        @media screen and (max-width: $media-mobile-min) {
          width: 20px;
        }
      }
    }

    .si-win {
      &::after {
        background: url(../images/won.png);
      }
    }

    .si-tie {
      &::after {
        background: url(../images/tie.png);
      }
    }

    .si-lost {
      &::after {
        background: url(../images/lost.png);
      }
    }
  }

  .si-playersBox {
    @include flexbox;
    justify-content: space-between;
    align-items: flex-start;
    text-align: center;

    @media screen and (max-width: $media-mobile-land-sm-min) {
      flex-wrap: wrap;
    }
  }

  .si-head {
    font-size: 18px;
    font-family: $medium_font-family;
    font-weight: $medium_font-weight;
    color: $client-primary-color;
    text-transform: uppercase;

    @media screen and (max-width: $media-desktop-min) {
      font-size: 16px;
    }

    @media screen and (max-width: $media-mobile-max) {
      font-size: 15px;
    }

    @media screen and (max-width: $media-mobile-land-sm-min) {
      font-size: 18px;
    }
  }

  .si-player {
    flex: 0 0 25.5%;
    width: 25.5%;
    padding: 20px 15px 19px;
    @include linearGradient(rgba(0, 0, 0, 0) 50%, rgba($lightGary, 1) 100%);

    @media screen and (max-width: $media-desktop-min) {
      flex: 0 0 47%;
      width: 47%;
      padding: 10px 15px 10px;
    }

    @media screen and (max-width: $media-mobile-land-sm-min) {
      flex: 0 0 100%;
      width: 100%;
      margin-top: 15px;
      padding: 20px 15px 19px;
    }

    .si-playerImg {
      background-repeat: no-repeat;
      background-size: contain;
      height: auto;
      max-width: 100%;
      background-repeat: no-repeat;
      background-position: center bottom;
      margin: 30px auto 15px;
      border-bottom: 1px solid $black;

      @media screen and (max-width: $media-desktop-min) {
        height: 160px;
        background-position: center top;
      }

      @media screen and (max-width: $media-mobile-max) {
        height: 260px;
        margin-top: 5px;
      }

      @media screen and (max-width: $media-mobile-land-sm-min) {
        height: 300px;
      }

      @media screen and (max-width: $media-mobile-esm-max) {
        height: 245px;
      }
    }

    .si-playerName {
      span {
        display: block;
        text-transform: uppercase;
        font-size: 22px;
        color: $client-primary-color;
        line-height: 1;
        font-family: $semibold_font-family;
        font-weight: $semibold_font-weight;

        @media screen and (max-width: $media-desktop-min) {
          font-size: 16px;
        }

        @media screen and (max-width: $media-mobile-land-sm-min) {
          font-size: 22px;
        }
      }

      .si-surname {
        font-family: $light_font-family;
        font-weight: $light_font-weight;
      }
    }

    .si-num {
      font-size: 50px;
      font-family: $medium_font-family;
      font-weight: $medium_font-weight;
      color: $client-primary-color;
      line-height: 1;
      margin-top: 5px;

      @media screen and (max-width: $media-desktop-min) {
        font-size: 30px;
      }

      @media screen and (max-width: $media-mobile-max) {
        font-size: 40px;
      }

      @media screen and (max-width: $media-mobile-land-sm-min) {
        font-size: 45px;
      }
    }
  }
}

.hi-page,
.hindi-page {
  .si-piechart-container .si-player .si-playerName span {
    font-weight: bold;
    line-height: 1.4;
  }

  .si-piechart-container .si-player .si-playerName .si-surname {
    font-weight: normal;
  }

  .si-piechart-container .si-head {
    font-weight: bold;
  }
}