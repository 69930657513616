@import "common/_variables";
@import "common/_mixins";

.homepage-masthead{
  margin: 0 -10px;
}

.main-top-widgets-wrapper {
  user-select: none;

  .si-cwl-mast-head-body {

    .si-common-class a:focus,
    .si-main-wrap a:focus {
      background-color: #d1ab3e;
    }
  }

}

.tableCell {
  display: table-cell;
  vertical-align: middle;
}

.si-top-head-wrap {
  justify-content: center;
  align-items: center;
  display: flex;
  width: 40%;
  margin: 50px auto 0;

  @media screen and (max-width: $media-tablet-max) {
    width: 60%;
  }

  @media screen and (max-width: $media-mobile-max) {
    margin-bottom: 10px;
    width: 100%;
  }

  @media screen and (max-width: $media-mobile-esm-max) {
    margin: 25px auto 0;
  }
}

.si-top-wrap {
  max-width: 100%;
  margin: -1px auto 10px auto;
  background: rgba(#0a478e, 1);
}

.mainBox {
  display: block;
}

.matchCenter-backWrap {
  margin: 15px 0;
  display: table;

  @media screen and (max-width: $media-mobile-max) {
    width: 100%;
    padding: 0 10px;
  }

  a {
    display: table-cell;
    vertical-align: middle;
    padding: 4px 20px;
    font-size: 12px;
    width: 100%;
    min-width: 350px;
    color: $white;
    background: $client-primary-color;
    @include border-radius(5px);

    @media screen and (max-width: $media-mobile-max) {
      min-width: 100%;
    }

    i {
      font-size: 16px;
    }

    img {
      width: 60px;
      height: auto;
      margin: 0 10px;
    }

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}

.si-masthead-cont {
  width: 100%;
  background: url(../images/matchHead-bg.png) #083f88 no-repeat center top;
  @include background-size(cover);
  position: relative;
  min-height: 350px;
  height: 500px;

  @media screen and (max-width: $media-mobile-max) {
    min-height: 290px;
    height: 450px;
  }

  @media screen and (max-width: $media-mobile-esm-max) {
    min-height: 250px;
    height: 375px;
  }
}

.si-masthead-data {
  min-height: auto;
  height: auto;
  padding-bottom: 10px;

  @media screen and (max-width: $media-mobile-max) {
    min-height: auto;
    height: auto;
  }

  @media screen and (max-width: $media-mobile-esm-max) {
    min-height: auto;
    height: auto;
  }
}

.si-cwl-mast-head-wrap {
  .si-cwl-mast-head-title {
    font-size: 16px;
    text-transform: uppercase;
    text-align: center;
    position: relative;
    display: block;
    margin: 0;
    z-index: 1;
    flex-basis: auto;
    padding-right: 10px;
    text-align: right;
    color: $white;
    font-family: $medium_font-family;

    @media screen and (max-width: $media-tablet-max) {
      background-size: cover;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -ms-background-size: cover;
      -o-background-size: cover;
    }

    @media screen and (max-width: $media-mobile-max) {
      font-size: 14px;
    }

    @media screen and (max-width: $media-mobile-sm-max) {
      font-size: 12px;
    }

    @media screen and (max-width: $media-mobile-esm-max) {
      font-size: 10px;
      padding: 10px;
    }

    span {
      display: block;
    }
  }

  .arrow-wrapper {
    position: absolute;
    right: 10px;
    top: 50%;
    display: none;
    width: 26px;
    height: 26px;
    border: 2px solid $white;
    color: $white;
    @include border-radius(50px);
    cursor: pointer;
    z-index: 1;

    @media screen and (max-width: $media-mobile-max) {
      width: 16px;
      height: 16px;
      border: 1px solid $white;
      display: none;
    }

    &:hover {
      .tooltip {
        display: block;
      }
    }

    .tooltip {
      display: none;
      width: 120px;
      background-color: $client-secondary-color;
      color: $white;
      padding: 5px;
      box-sizing: border-box;
      font-size: 14px;
      font-family: $regular_font-family;
      position: absolute;
      top: -35px;
      left: -50px;
    }

    .plus-arrow,
    .minus-arrow {
      &:before {
        font-family: "icomoon" !important;
        font-size: 10px;
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;

        @media screen and (max-width: $media-mobile-max) {
          font-size: 6px;
        }
      }
    }

    .plus-arrow {
      &:before {
        content: "\e906";
        font-size: 12px;

        @media screen and (max-width: $media-mobile-max) {
          font-size: 8px;
        }
      }
    }

    .minus-arrow {
      &:before {
        content: "\e907";
      }
    }
  }

  .si-cwl-mast-head-body {
    text-align: center;
    position: relative;
    text-transform: capitalize;
    font-size: 14px;
    overflow: hidden;
    padding-bottom: 20px;
    color: $white;
    font-family: $regular_font-family;

    @media screen and (max-width: $media-mobile-max) {
      padding-top: 10px;
    }



    .si-vs-txt {
      text-transform: lowercase;
    }

    .si-cwl-mast-head-match-status {
      font-size: 14px;
      text-transform: uppercase;
      position: absolute;
      top: 0;
      width: 100%;
      height: 35px;
      color: $client-primary-color;
      font-family: $medium_font-family;
      border-top: 5px solid $client-complementary-color;

      @media screen and (max-width: $media-mobile-max) {
        font-size: 16px;
      }

      @media screen and (max-width: $media-mobile-esm-max) {
        font-size: 12px;
      }

      span {
        background: $client-complementary-color;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        width: 170px;
        height: 30px;
        margin: 0 auto;
        // margin-top: -10px;
        display: flex;

        @media screen and (max-width: $media-mobile-max) {
          font-size: 12px;
          width: 150px;
          margin-top: -5px;
        }

        @media screen and (max-width: $media-mobile-esm-max) {
          font-size: 10px;
        }
      }
    }

    .si-cwl-mast-head-scorecard-wrap {
      padding: 25px 10px;
      width: 70%;
      margin: 0 auto;

      @media screen and (max-width: $media-tablet-max) {
        width: 90%;
      }

      @media screen and (max-width: $media-mobile-max) {
        width: 100%;
        padding: 5px 0;
      }

      .si-cwl-mast-head-scorecard-tbl {
        display: table;
        width: 100%;
        z-index: 1;
        position: relative;

        @media screen and (max-width: $media-mobile-sm-max) {
          padding: 0 5px;
        }

        .si-cwl-mast-head-scorecard-td {
          width: 20%;
          vertical-align: top;

          &:first-child {
            text-align: right;
            vertical-align: top;
          }

          &:last-child {
            text-align: left;
            vertical-align: top;
          }

          &:first-child,
          &:last-child {
            width: 40%;

            @media screen and (max-width: $media-mobile-max) {
              text-align: center;
            }
          }

          .socrecard-teamA-wrap.loss-match,
          .socrecard-teamB-wrap.loss-match {
            .scorecard-innings-wrap {
              span {
                &:first-child {
                  color: rgba($client-primary-color, 0.4);
                }

                &:last-child {
                  color: rgba($client-primary-color, 0.4);
                }
              }
            }
          }

          .socrecard-teamA-wrap.won-match,
          .socrecard-teamB-wrap.won-match {
            .scorecard-innings-wrap {
              color: $client-complementary-color;

              .si-overs {
                color: #aaaaaa !important;
              }
            }
          }

          .socrecard-teamA-wrap,
          .socrecard-teamB-wrap {
            font-family: $bold_font-family;
            font-size: 20px;
            text-transform: uppercase;

            @media screen and (max-width: $media-mobile-max) {
              font-size: 16px;
            }

            @media screen and (max-width: $media-mobile-sm-max) {
              font-size: 14px;
            }

            @media screen and (max-width: $media-mobile-esm-max) {
              font-size: 10px;
            }

            .scorecard-team-name {
              position: relative;
              height: 50px;
              display: flex;
              justify-content: flex-end;
              flex-direction: row;
              align-items: center;

              @media screen and (max-width: $media-mobile-max) {
                padding: 5px 0;
                height: auto;
                flex-direction: row-reverse;
                justify-content: flex-start;
              }

              .team-name-box {
                position: absolute;
                line-height: 1.1;
                top: 50%;

                @media screen and (max-width: $media-mobile-max) {
                  position: static;
                  top: 0;
                }

                .full-name {
                  display: block;

                  @media screen and (max-width: $media-mobile-max) {
                    display: block;
                  }
                }

                .short-name {
                  display: none;

                  @media screen and (max-width: $media-mobile-max) {
                    display: none;
                  }
                }
              }

              span {
                &:first-child {
                  float: right;
                  margin: -3px 0 0;

                  @media screen and (max-width: $media-mobile-max) {
                    float: none;
                    display: block;
                    margin: 0 0 5px;
                  }
                }

                &:last-child {
                  @media screen and (max-width: $media-mobile-max) {
                    height: auto;
                    display: block;
                    line-height: 16px;
                    font-size: 14px;
                  }
                }

                img {
                  max-width: 80px;
                  max-height: 80px;
                  @include border-radius(5px);
                  background: $white;

                  @media screen and (max-width: $media-mobile-max) {
                    max-width: 40px;
                    max-height: 40px;
                  }
                }
              }
            }

            .scorecard-team-name.si-cwl-bowling-team {
              &:before {
                display: none;
                background: url("../images/ball_icon.png") no-repeat center center;
              }
            }

            .scorecard-team-name.si-cwl-batting-team {
              &:before {
                display: none;
                background: url("../images/bat_icon.png") no-repeat center center;
              }
            }

            .scorecard-team-name.si-cwl-batting-team,
            .scorecard-team-name.si-cwl-bowling-team {
              position: relative;

              &:before {
                content: "";
                position: absolute;
                width: 20px;
                height: 20px;
                background-size: 100%;
                top: 50%;

                @media screen and (max-width: $media-mobile-max) {
                  top: 20px;
                }
              }
            }

            .score-container {
              display: table;
              width: 100%;
              margin-top: 10px;

              @media screen and (max-width: $media-mobile-max) {
                margin-top: 0;
                padding: 15px 0;
              }
            }

            .scorecard-innings-wrap {
              text-transform: none;
              color: #aaaaaa;
              padding-top: 5px;

              @media screen and (max-width: $media-mobile-max) {
                display: inline-block;
                padding: 0px 1px;

                &:nth-child(2) {
                  border-left: 1px solid $lightGary;

                  @media screen and (max-width: $media-mobile-max) {
                    border-left: 0px;
                  }
                }
              }

              span {
                &:first-child {
                  font-family: arial, sans-serif;
                  font-weight: 700;
                  font-size: 34px;
                  padding-right: 10px;

                  @media screen and (max-width: $media-mobile-max) {
                    font-size: 30px;
                  }

                  @media screen and (max-width: $media-mobile-esm-max) {
                    font-size: 16px;
                  }
                }

                &.si-overs {
                  font-family: $bold_font-family;
                  font-size: 12px;
                  text-transform: uppercase;

                  @media screen and (max-width: $media-mobile-esm-max) {
                    font-size: 10px;
                  }
                }

                &.ytb {
                  font-family: $bold_font-family;
                  font-size: 30px;
                  text-transform: capitalize;

                  @media screen and (max-width: $media-mobile-max) {
                    font-size: 16px;
                    padding-top: 8px;
                  }

                  @media screen and (max-width: $media-mobile-sm-max) {
                    font-size: 13px;
                    padding-top: 0;
                  }
                }
              }
            }

            .scorecard-innings-wrap.scorecard-innings-current,
            .scorecard-innings-wrap.scorecard-innings-winner {
              color: $client-complementary-color;
            }
          }

          .socrecard-teamA-wrap {
            .scorecard-innings-wrap {
              justify-content: flex-end;
              align-items: center;
              display: flex;
            }

            .team-name-box {
              left: auto;
              right: 90px;
              text-align: right;
            }

            img {
              margin: 0 0 0 14px;

              @media screen and (max-width: $media-mobile-max) {
                margin-right: 10px;
              }
            }
          }

          .socrecard-teamB-wrap {
            .scorecard-innings-wrap {
              justify-content: flex-start;
              align-items: center;
              display: flex;
            }

            .team-name-box {
              right: 0;
              left: 90px;
              text-align: left;
            }

            .scorecard-team-name.si-cwl-batting-team,
            .scorecard-team-name.si-cwl-bowling-team {
              &:before {
                left: -30px;

                @media screen and (max-width: $media-mobile-max) {
                  left: 10px;
                }
              }
            }

            span {
              img {
                @media screen and (max-width: $media-mobile-max) {
                  margin-left: 10px;
                }
              }
            }

            .scorecard-team-name {
              flex-direction: row-reverse;

              @media screen and (max-width: $media-mobile-max) {
                flex-direction: row;
                align-items: center;
                height: 50px;
              }

              span {
                &:first-child {
                  float: left;

                  @media screen and (max-width: $media-mobile-max) {
                    float: none;
                  }
                }
              }
            }

            img {
              margin: 0 14px 0 0;
            }
          }

          .socrecard-teamA-wrap {

            .scorecard-team-name.si-cwl-batting-team,
            .scorecard-team-name.si-cwl-bowling-team {
              &:before {
                right: -30px;

                @media screen and (max-width: $media-mobile-max) {
                  right: 10px;
                }
              }
            }

            .scorecard-team-name {
              @media screen and (max-width: $media-mobile-max) {
                height: 50px;
              }
            }




          }

          .socrecard-teamA-wrap.won-match,
          .socrecard-teamB-wrap.won-match {
            color: $white;
          }

          .team-vs-wrap {
            margin: 0 auto;
            font-size: 18px;
            width: 55px;
            margin: 15px auto 0;
            position: relative;
            background: #07113f;
            text-align: center;
            height: 25px;

            @media screen and (max-width: $media-mobile-max) {
              width: 45px;
              height: 20px;
            }

            span {
              font-family: $regular_font-family;
              color: $white;
              text-transform: uppercase;
              @include transform(translateY(-50%));

              @media screen and (max-width: $media-mobile-max) {
                font-size: 16px;
              }

              @media screen and (max-width: $media-mobile-esm-max) {
                font-size: 13px;
              }
            }
          }
        }
      }
    }

    .si-cwl-mast-head-match-result-wrap span {
      padding: 0;
      min-height: 35px;
      width: 45%;
      margin: 0px auto;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 14px;
      display: block;
      position: relative;
      z-index: 1;
      text-transform: none;
      line-height: 1;
      background: $client-primary-color;
      font-family: $regular_font-family;
      color: $white;
      display: flex;
      @include border-radius(20px);

      @media screen and (max-width: $media-mobile-max) {
        width: 80%;
        padding: 0 10px;
      }

      @media screen and (max-width: $media-mobile-sm-max) {
        font-size: 12px;
      }
    }

    .si-cwl-mast-head-match-venue-time {
      text-align: center;
      padding: 10px 5px;
      font-size: 14px;
      text-transform: capitalize;
      font-family: $regular_font-family;
      color: $white;
      position: relative;

      @media screen and (max-width: $media-mobile-max) {
        font-size: 14px;
        padding: 5px;
      }

      @media screen and (max-width: $media-mobile-sm-max) {
        font-size: 12px;
      }

      span {
        display: block;

        em {
          font-style: normal;
          padding: 0 8px;
        }
      }

      &:after {
        content: "";
        position: absolute;
        top: 50%;
        width: 1px;
        height: 15px;
        left: 0;
        background: $white;
        @include transform(translateY(-50%));

        @media screen and (max-width: $media-mobile-esm-max) {
          height: 12px;
        }
      }
    }

    .si-cwl-mast-head-match-venue-time {
      padding-left: 10px;
      flex-basis: auto;
      text-align: left;

      @media screen and (max-width: $media-mobile-sm-max) {
        font-size: 12px;
      }

      @media screen and (max-width: $media-mobile-esm-max) {
        font-size: 10px;

        span {
          em {
            padding: 0 10px;
          }
        }
      }
    }

    .superOver-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 0;
      width: 40%;
      margin: 10px auto;
      background: rgba(193, 207, 243, 0.9);
      @include border-radius(10px);

      @media screen and (max-width: $media-tablet-max) {
        width: 60%;
      }

      @media screen and (max-width: $media-mobile-max) {
        width: 100%;
      }

      .superOverLeft {
        text-align: right;

        @media screen and (max-width: $media-mobile-max) {
          padding: 0 5px 0 0;
        }
      }

      .superOverRight {
        text-align: left;

        @media screen and (max-width: $media-mobile-max) {
          padding: 0 0 0 5px;
        }
      }

      .superOverLeft,
      .superOverRight {
        flex-basis: 30%;
        flex-grow: 2;

        .superOverTeamScore {
          font-size: 26px;
          color: $superOver;
          font-family: $bold_font-family;

          @media screen and (max-width: $media-mobile-max) {
            font-size: 16px;
          }

          &.currentPlaying {
            opacity: 1;
            color: $superOver;
          }

          &.matchWon {
            opacity: 1;
            color: $superOver;
          }

          .scorecard-innings-wrap {
            span {
              &:last-child {
                font-size: 12px;
                font-family: $regular_font-family;
              }
            }
          }
        }
      }

      .superOverMid {
        flex-grow: 1;
        font-size: 12px;
        flex-basis: 60%;

        @media screen and (max-width: $media-tablet-max) {
          width: 140px;
        }

        @media screen and (max-width: $media-mobile-max) {
          width: 100px;
        }

        .superOverMidTitle {
          padding: 2px 5px;
          color: $superOver;
          text-transform: capitalize;
          font-family: $regular_font-family;

          @media screen and (max-width: $media-mobile-max) {
            font-size: 11px;
          }
        }
      }
    }
  }
}


.si-vs-team {
  flex-basis: 34%;
  text-align: center;
  position: relative;
}

.si-svg-bg {
  fill: #c7c7c8;
  stroke-width: 5px;
  stroke: #676774;
  display: none;
}

.si-VsWrap {
  position: relative;
  padding-top: 15px;
}

.si-cwl-mast-head-body {
  .si-btn-wrap {
    justify-content: center;
    display: flex;
    padding-top: 20px;
  }

  .si-action {
    position: relative;
    font-family: $medium_font-family;
    font-size: 14px;
    margin-right: 15px;
    color: $client-primary-color;
    text-align: center;
    background-color: $client-complementary-color;
    width: 130px;
    height: 30px;
    text-transform: capitalize;
    align-items: center;
    justify-content: center;
    @include border-radius(20px);

    @media screen and (max-width: $media-mobile-sm-max) {
      font-size: 12px;
    }

    @media screen and (max-width: $media-mobile-esm-max) {
      font-size: 10px;
    }

    a {
      position: relative;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    &:last-child {
      margin-right: 0;
    }

    &.si-mat-report {
      background: $transparent;
      border: 1px solid $white;
      color: $white;
    }
  }

  .si-btn-common {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.si-widget {
  &.si-upcoming {
    .score-container {
      display: none !important;
    }

    .si-cwl-mast-head-wrap .si-cwl-mast-head-body .si-cwl-mast-head-scorecard-wrap .si-cwl-mast-head-scorecard-tbl .si-cwl-mast-head-scorecard-td {
      vertical-align: middle;
    }

    .si-data-wraper {
      padding: 10px;
    }

    .mainBox {
      margin-top: 0;
    }

    .tab-wrapper ul {
      width: 99%;
    }

    .si-vs-svg {
      margin-top: 0;
    }

    .si-timer-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .si-time-data {
      position: relative;

      &:after {
        content: "";
        position: absolute;
        left: auto;
        right: -2px;
        top: 50%;
        @include transform(translateY(-50%));
        height: 30%;
        width: 1px;
        background: $white;

        @media screen and (max-width: $media-mobile-max) {
          right: -5px;
        }
      }

      &:last-child {
        &:after {
          display: none;
        }
      }
    }

    .si-txt-wrap {
      display: flex;
      padding: 0 5px;
      align-items: center;
      width: 70px;

      .si-number {
        width: 25px !important;
      }

      .si-txt {
        width: 35px !important;
        margin-left: -2px;

        @media screen and (max-width: $media-mobile-max) {
          width: 25px !important;
        }
      }
    }

    .si-cwl-mast-head-wrap {
      .si-cwl-mast-head-title {
        flex-basis: auto;
        width: auto;
        margin: 0;

        &:after {
          display: none;
        }

        span {
          text-align: center;
        }
      }

      .si-cwl-mast-head-body {
        @media screen and (max-width: $media-mobile-sm-max) {
          padding-bottom: 20px;
        }

        .si-cwl-mast-head-match-status span {
          width: 190px;
          font-size: 14px;
          padding-top: 5px;

          @media screen and (max-width: $media-mobile-sm-max) {
            font-size: 12px;
            width: 150px;
          }
        }

        .si-cwl-mast-head-match-result-wrap span {
          @media screen and (max-width: $media-mobile-max) {
            width: 90%;
          }
        }
      }
    }

    .si-top-head-wrap {
      width: 40%;
      margin: 30px auto 0;

      @media screen and (max-width: $media-tablet-max) {
        width: 60%;
      }

      @media screen and (max-width: $media-mobile-max) {
        width: 100%;
      }
    }

    .scorecard-team-name {
      .si-cwl-bowling-team {
        flex-direction: row-reverse !important;
      }
    }
  }
}

.hi-page,
.hindi-page {
  .si-cwl-mast-head-wrap .si-cwl-mast-head-body .si-cwl-mast-head-match-result-wrap span {
    line-height: 1.4;
    min-height: 40px;
  }

  .team-name-box,
  .si-cwl-mast-head-wrap .si-cwl-mast-head-title,
  .si-cwl-mast-head-wrap .si-cwl-mast-head-body .si-cwl-mast-head-match-status {
    font-weight: bold;
  }
}