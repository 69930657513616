@import "common/_variables";
@import "common/_mixins";
.si-staspg-cntnr {
  position: relative;
  .si-header-wrap {
    border-bottom: none;
  }
  .si-dropdown-contnr {
    margin-bottom: -60px;
    justify-content: flex-end;
    @media screen and (max-width: $media-mobile-max) {
      margin-bottom: 0;
      justify-content: center;
    }  
    .si-dropdown-wrap {
      @media screen and (max-width: $media-mobile-max) {
        width: 100%;
      }
      .si-selectWrap {
        @media screen and (max-width: $media-mobile-max) {
          width: 100%;
        }
      }
    }
    .si-dropdown-teams {
      position: relative;
      @media screen and (max-width: $media-mobile-max) {
        width: 100%;
      }
    }
    .si-label {
      position: absolute;
      top: -10px;
      left: 0;
      padding-left: 8px;
      font-size: 12px;
    }
  } 
}
.si-stats-wrapper {
  .si-row-data {
    justify-content: flex-start;
    flex-direction: column;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    @include border-radius(0 0 30px 30px);
  }
  .si-stats-row {
    padding: 0 10px;
    height: 60px;
    background-color: $white;
    position: relative;
    align-items: center;
    justify-content: space-around;
    @include flexbox;
    &:nth-child(even) {
      background: #efefef;
    }
    &.si-stats-th {
      background-color: $client-primary-color;
      border-bottom: none;
      .si-stats-cell {
        font-size: 16px;
        color: $white;
        font-family: $semibold_font-family;
        font-weight: $semibold_font-weight;
        @media screen and (max-width: $media-mobile-max) {
          font-size: 20px;
        }
        @media screen and (max-width: $media-mobile-max) and (orientation: landscape) {
          font-size: 16px;
        }
        @media screen and (max-width: $media-mobile-land-sm-min) {
          font-size: 14px;
        }
      }
    }
  }
  .si-stats-cell {
    font-size: 20px;
    flex-basis: 9%;
    justify-content: center;
    align-items: center;
    line-height: 1;
    text-align: center;
    font-family: $regular_font-family;
    font-weight: $regular_font-weight;
    color: $client-primary-color;
    @include flexbox;
    @media screen and (max-width: $media-tablet-max) {
      font-size: 16px;
      flex-basis: auto;
      flex: 1;
    }
    @media screen and (max-width: $media-mobile-max) {
      font-size: 20px;
    }
    @media screen and (max-width: $media-mobile-max) and (orientation: landscape) {
      font-size: 16px;
    }
    @media screen and (max-width: $media-mobile-land-sm-min) {
      font-size: 14px;
    }
    &.si-player {
      flex-basis: 25%;
      font-size: 18px;
      padding-left: 10px;
      justify-content: flex-start;
      text-align: left;
      font-family: $medium_font-family;
      font-weight: $medium_font-weight;
      @media screen and (max-width: $media-mobile-max) {
        flex-basis: 10%;
        font-size: 20px;
      }
      @media screen and (max-width: $media-mobile-max) and (orientation: landscape) {
        font-size: 16px;
      }
      @media screen and (max-width: $media-mobile-land-sm-min) {
        font-size: 16px;
      }
      span {
        border: 1px solid $transparent;
      }
    }
    &.si-century,
    &.si-highwkts {
      flex-basis: 12%;
      @media screen and (max-width: $media-tablet-max) {
        flex-basis: auto;
        min-width: 120px;
        flex: 1;
      }
    }
  }
  .si-view-morebtn {
    text-transform: capitalize;
    font-size: 18px;
    cursor: pointer;
    margin: 0 0 50px 0;
    outline: none;
    color: $client-primary-color;
    font-family: $medium_font-family;
    font-weight: $medium_font-weight;
    span {
      position: relative;
      padding: 0 10px;
      margin: 20px 0;
      min-width: 125px;
      float: right;
      &:after {
        content: "\e904";
        position: absolute;
        left: auto;
        right: 5px;
        top: 50%;
        @include transform(translateY(-50%));
        color: $client-primary-color;
        font-family: "dataviz-icon-fonts" !important;
        font-size: 14px;
      }
    }
    &.si-viewless-bowling,
    &.si-viewless-batting {
      span {
        &:after {
          @include transform(translateY(-50%) rotate(180deg));
        }
      }
    }
  }
  .si-data-content {
    width: 100%;
    height: auto;
    @media screen and (max-width: $media-mobile-max) {
      margin-bottom: 5px;
    }

    .si-content-swiper {
      width: 100%;
      height: 100%;
      .si-stats-row {        
        @include box-sizing(border-box);
      }
      .swiper-scrollbar-drag {
        background: rgba($client-primary-color, 1);
      }
      .swiper-scrollbar {
        width: 4px !important;
        top: 2% !important;
        height: 95% !important;
        outline: none;
        background: rgba(229, 229, 229, 1);
        @media screen and (max-width: $media-mobile-max) {
          display: none;
        }
      }
    }   
  }
  .si-mscroll {
    @media screen and (max-width: $media-tablet-max) {
      width: 1140px;
      overflow-x: auto;
    }
    @media screen and (max-width: $media-mobile-sm-max) {
      width: 800px;
    }
  }
  .si-intwrp {
    width: 100%;
    @media screen and (max-width: $media-tablet-max) {
      overflow-x: auto;
    }
  }
  .si-data-wrp {
    position: relative;
    @media screen and (max-width: $media-tablet-max) {
      overflow-x: auto;
      &:after {
        content: "";
        position: absolute;
        left: auto;
        right: 0;
        width: 30px;
        z-index: 1;
        height: 100%;
        top: 50%;
        @include transform(translateY(-50%));
        @include linearGradient(left, rgba(0, 0, 0, 0), rgba(255, 255, 255, 0.4));
      }
    }
  }
}
