@import "common/_variables";
@import "common/_mixins";
.si-sqaud-container {
  /*max-width: 1170px;*/
  margin: 0 auto;
  .gender-toggle {
    margin-right: 10px;
    @media screen and (max-width: $media-mobile-max) {
      justify-content: center;
      margin: 0 auto;
    }
  }
  .si-header-wrap {
    justify-content: flex-end;
    @media screen and (max-width: $media-mobile-max) {
      align-items: flex-start;
      flex-direction: column;
      width: 100%;
    }
    .si-dropdown-contnr {
      //margin-top: -15px;
      @media screen and (max-width: $media-tablet-max) {
        margin-top: -10px;
      }
      @media screen and (max-width: $media-mobile-max) {
        margin-top: 0;
        flex-basis: 100%;
        width: 100%;
        flex-direction: column;
        align-items: flex-end;
      }
      .si-dropdown-wrap {
        margin-left: 40px;
        order: 2;
        @media screen and (max-width: $media-mobile-max) {
          width: 100%;
          order: 1;
          margin-left: 0;
        }
        .si-selectWrap {
          font-size: 18px;
          width: 170px;
          height: 45px;
          text-transform: capitalize;
          border: 1px solid $client-primary-color;
          @include border-radius(50px);
          @media screen and (max-width: $media-mobile-max) {
            width: 180px;
            font-size: 14px;
          }
          @media screen and (max-width: $media-mobile-esm-max) {
            width: 140px;
            height: 30px;
            font-size: 12px;
          }
        }
        .selectActive {
          padding: 0 15px;
        }
        ul {
          @media screen and (max-width: $media-mobile-max) {
            justify-content: flex-end;
            flex-basis: 40%;
          }
        }
      }
      .si-tab-wrp {
        order: 1;
        @media screen and (max-width: $media-mobile-max) {
          order: 2;
        }
        .si-tabs {
          font-size: 18px;
          height: 30px;
          cursor: pointer;
          @media screen and (max-width: $media-mobile-max) {
            width: 100%;
            margin-top: 0px;
          }
          @media screen and (max-width: $media-mobile-esm-max) {
            font-size: 14px;
          }
        }
      }
    }
  }
  .si-SuppStaff {
    padding-top: 20px;
    .si-plyer-section {
      .si-plyer-img {
        background: url(../images/staff-plaholder.png) no-repeat center top;
      }
    }
  }
  .si-squad-coach {
    .si-plyer-section {
      &:hover {
        pointer-events: none;
        .si-plyer-img {
          opacity: 1;
        }
      }
    }
  }
  //switch toggle Start
  .si-toggle-wrapper {
    position: relative;
    display: none;
    width: 180px;
    margin: 20px auto;
    @media screen and (max-width: $media-mobile-max) {
      display: none;
      flex-basis: 100%;
      width: 100%;
    }
    @media screen and (max-width: $media-mobile-esm-max) {
      margin: 10px auto;
    }
    .si-language-toggle {
      position: absolute;
      left: 10px;
      top: 50%;
      @include transform(translateY(-50%));
      font-size: 0;
      color: $client-primary-color;
      @media screen and (max-width: $media-mobile-max) {
        left: 0;
        right: 0;
      }
      span {
        font-size: 18px;
        text-transform: uppercase;
        font-family: $regular_font-family;
        font-weight: $regular_font-weight;
        @media screen and (max-width: $media-mobile-esm-max) {
          font-size: 14px;
        }
      }
      .si-content-box {
        text-align: center;
        @media screen and (max-width: $media-mobile-max) {
          justify-content: center;
          align-items: center;
          @include flexbox;
          margin: 0 auto;
        }
      }
      .si-toggle-radio {
        margin: 0 10px;
        color: #fff;
        height: 20px;
        position: relative;
        width: 50px;
        background: rgba($client-complementary-color, 0.1);
        @include border-radius(20px);
        border: 1px solid $client-primary-color;
        input {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: 2;
          margin: 0;
          padding: 0;
          cursor: pointer;
          opacity: 0;
        }
        span {
          width: 100%;
          height: 100%;
          &:before {
            background-color: $client-complementary-color;
            @include border-radius(50%);
            content: "";
            display: block;
            height: 16px;
            width: 16px;
            left: 2px;
            margin-top: -8px;
            position: absolute;
            top: 50%;
            transition: all 0.3s ease 0s;
          }
        }
      }
      .si-toggle-radio input:checked ~ span::before {
        left: 30px;
      }
      em {
        font-style: normal;
      }
    }
  }
  .si-section-data {
    margin: 0;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-bottom: 40px;
    padding-top: 30px;
    @media screen and (max-width: $media-desktop-min) {
      margin: 0;
    }
    @media screen and (max-width: $media-mobile-sm-max) {
      display: block;
      margin: 0;
    }
  }
  .si-plyer-section {
    flex-basis: calc(25% - 15px);
    flex-shrink: 0;
    position: relative;
    margin: 0 15px 50px 0;
    padding: 0px;
    cursor: pointer;
    @include linearGradient(rgba(255, 255, 255, 0), rgba(246, 246, 246, 0.8), rgba(226, 226, 226, 0.5));
    @media screen and (max-width: $media-tablet-max) {
      flex-basis: calc(33.33% - 16px);
      padding: 20px 0;
    }
    @media screen and (max-width: $media-mobile-max) {
      flex-basis: calc(50% - 30px);
      margin: 0 auto;
    }
    @media screen and (min-width: $media-mobile-land-sm-max) and (max-width: $media-mobile-max) {
      flex-basis: calc(50% - 30px);
      margin: 0 auto;
      padding: 20px 0;
    }
    @media screen and (max-width: $media-mobile-sm-max) {
      width: 100%;
      flex-basis: 100%;
    }
    @media screen and (max-width: $media-mobile-max) {
      &.si-emojis {
        .si-plyer-img-hover {
          display: block;
          opacity: 1;
          width: 100%;
          height: 200px;
          top: 20px;
        }
      }
    }
    .si-captain,
    .si-foreign-plyr {
      position: absolute;
      &:before {
        position: absolute;
        top: 50%;
        @include transform(translateY(-50%));
      }
    }
    .si-foreign-plyr {
      left: auto;
      width: 20px;
      height: 20px;
      right: 20px;
      &:before {
        font-size: 18px;
        content: "\e902";
        font-family: "dataviz-icon-fonts" !important;
        color: $client-primary-color;
        right: 0;
      }
    }
    .si-captain {
      right: auto;
      top: 10px;
      left: 20px;
      width: 26px;
      height: 26px;
      &:before {
        content: "C";
        width: 100%;
        height: 100%;
        font-size: 16px;
        right: 0;
        justify-content: center;
        align-items: center;
        background: $client-primary-color;
        font-family: $regular_font-family;
        color: $white;
        @include flexbox;
        @include border-radius(50px);
      }
    }
    .si-plyer {
      position: relative;
      height: 250px;
      width: 100%;
      margin: 0 auto;
      @media screen and (max-width: $media-desktop-min) {
        height: 220px;
      }
      &:after {
        content: "";
        width: 70%;
        height: 1px;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: auto;
        margin: 0 auto;
        background: $client-primary-color;
      }
    }
    .si-plyer-img,
    .si-plyer-img-hover {
      position: absolute;
      display: block;
      left: 0;
      right: 0;
      height: 100%;
      margin: 0px auto;
      width: 100%;
      object-fit: contain;
      @include background-size(contain);
      background-position: center bottom;
      background-repeat: no-repeat;
      @include transition(opacity, 0.5s, ease);
    }
    .si-plyer-img-hover {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      display: none;
      @media screen and (max-width: $media-mobile-max) {
        pointer-events: none;
      }
    }
    .si-plyer-info {
      position: relative;
      height: 100%;
    }
    &.si-no-linkable {
      cursor: auto;
      .si-plyer-info {
        cursor: auto;
      }
    }
    &.hide-cursor {
      cursor: default;
      a {
        cursor: default;
      }
    }
  }
  .si-bg-lable {
    position: relative;
    padding-bottom: 20px;
  }
  .si-data-wrap {
    height: 100%;
    margin: 0px auto;
    padding: 5px 0;
    text-align: center;
    justify-content: center;
    align-items: center;
    @include flexbox;
    .si-surname {
      font-size: 20px;
      // font-family: $regular_font-family;
      // font-weight: $regular_font-weight;
    }
    .si-plyer-role {
      font-size: 14px;
      padding-bottom: 0;
      padding-top: 5px;
      &:before {
        content: none;
      }
    }
  }
  .si-plyer-name {
    text-transform: uppercase;
    padding-top: 10px;
    font-size: 20px;
    font-family: $regular_font-family;
    font-weight: $regular_font-weight;
    color: $client-primary-color;
    line-height: 1.2;
    position: relative;
    span {
      display: block;
    }
  }
  .si-plyer-role {
    font-size: 14px;
    text-transform: uppercase;
    text-align: center;
    font-family: $regular_font-family;
    font-weight: $regular_font-weight;
    color: $client-primary-color;
    span {
      padding: 0 25px;
    }
  }
}

// squad swiper widget
.si-squad-swiper {
  .si-squadWrap {
    padding-top: 30px;
  }
  /*_ homepage sqad strip _*/
  .swiper-slide {
    width: 25%;
    @media screen and (max-width: 576px) {
      width: 40%;
    }
    @media screen and (max-width: $media-mobile-max) {
      width: 100%;
    }
  }
  .si-plyer-section {
    @media screen and (max-width: $media-mobile-max) {
      width: calc(100% - 15px);
      flex-basis: 100%;
    }
    @media screen and (min-width: $media-mobile-land-sm-max) and (max-width: $media-mobile-max) {
      flex-basis: 100%;
      width: calc(100% - 15px);
    }
    .si-foreign-plyr {
      right: 10px;
    }
  }
  .si-header-wrap {
    border-bottom: none;
  }
  .swiper-pagination-bullet {
    width: 11px;
    height: 11px;
  }
  .swiper-pagination-bullet-active {
    opacity: 1;
    background: $client-complementary-color;
  }
}

.si-supportStaff {
  .si-header-wrap {
    border-bottom: 1px solid $client-complementary-color;
  }
}

//player-profile-squadwidget
.si-pp-squadwidget {
  .si-plyer-name {
    color: $client-primary-color;
  }
  .si-button-next,
  .si-button-prev {
    color: $client-primary-color;
  }
}

.hi-page,
.hindi-page {
  .si-sqaud-container {
    .si-plyer-name {
      line-height: 1.5;
      font-weight: bold;
    }
  }
}

.mr-page,
.marathi-page {
  .si-sqaud-container {
    .si-plyer-name {
      line-height: 1.5;
      // .si-name {
      //     font-weight: bold;
      // }
    }
  }
}
