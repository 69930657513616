@import "common/_variables";
@import "common/_mixins";
// @import "common/_fonts";

.si-main-wrap {
  margin: 0 auto;
}

.si-standing-container {
  .si-header-wrap {
    border-bottom: none;
  }
}

.si-standing-wrapper {
  .si-row-data {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    @include border-radius(0 0 30px 30px);
  }

  .si-stnd-row {
    height: 80px;
    background-color: $white;
    position: relative;
    @include flexbox;

    @media screen and (max-width: $media-mobile-max) {
      height: 75px;
    }

    &:nth-child(even) {
      background: #efefef;
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      height: 1px;
      margin: 0px auto;
      width: 95%;
      background: rgba($black, 0.2);
      bottom: 0;

      @media screen and (max-width: $media-mobile-max) {
        width: 90%;
      }
    }

    &.si-stnd-th {
      height: 70px;
      background-color: $client-primary-color;
      border-bottom: none;
      text-transform: capitalize;

      @media screen and (max-width: $media-mobile-max) {
        height: 60px;
      }

      .si-stnd-cell {
        font-size: 16px;
        color: $white;
        font-family: $semibold_font-family;
        //font-weight: $semibold_font-weight;

        @media screen and (max-width: $media-tablet-max) {
          font-size: 14px;
        }

        @media screen and (max-width: $media-mobile-esm-max) {
          font-size: 14px;
        }
      }
    }
  }

  .si-stnd-cell {
    font-size: 18px;
    flex-basis: 8%;
    justify-content: center;
    align-items: center;
    line-height: 1;
    font-family: $regular_font-family;
    font-weight: $regular_font-weight;
    color: $client-primary-color;
    @include flexbox;

    @media screen and (max-width: $media-tablet-max) {
      font-size: 16px;
    }

    @media screen and (max-width: $media-mobile-max) {
      flex-basis: 15%;
    }

    @media screen and (max-width: $media-mobile-esm-max) {
      font-size: 14px;
    }

    &.si-tms {
      flex-basis: 32%;
      justify-content: center;
      position: relative;
      font-family: $semibold_font-family;
      font-weight: $semibold_font-weight;

      @media screen and (max-width: $media-mobile-max) {
        flex-basis: 20%;
      }

      .si-qualified {
        position: absolute;
        left: -25px;
        top: 50%;
        @include transform(translateY(-50%));
        font-size: 20px;
        text-transform: uppercase;
        color: $client-secondary-color;
        font-family: $semibold_font-family;
        font-weight: $semibold_font-weight;

        @media screen and (max-width: $media-tablet-max) {
          font-size: 18px;
        }

        @media screen and (max-width: $media-mobile-max) {
          font-size: 16px;
        }

        @media screen and (max-width: $media-mobile-land-sm-min) {
          left: -18px;
        }

        @media screen and (max-width: $media-mobile-esm-max) {
          font-size: 14px;
          left: -12px;
        }
      }
    }

    &.si-wlt {
      display: none;

      @media screen and (max-width: $media-mobile-max) {
        @include flexbox;
      }
    }

    &.si-net {
      flex-basis: 10%;

      @media screen and (max-width: $media-mobile-max) {
        flex-basis: 20%;
      }
    }

    &.si-pts {
      flex-basis: 10%;
      font-family: $semibold_font-family;
      font-weight: $semibold_font-weight;

      @media screen and (max-width: $media-mobile-max) {
        flex-basis: 12%;
      }
    }

    @media screen and (max-width: $media-mobile-max) {

      &.si-tie {
        display: none;
      }
    }
  }

  .si-team-flag {
    display: block;
    width: 55px;
    height: 55px;
    background: no-repeat center center;
    @include background-size(contain);

    @media screen and (max-width: $media-tablet-max) {
      width: 40px;
      height: 40px;
    }

    @media screen and (max-width: $media-mobile-max) {
      display: block;
    }

    @media screen and (max-width: $media-mobile-esm-max) {
      max-width: 45px;
    }
  }

  .si-team-name {
    padding-left: 25px;

    @media screen and (max-width: $media-mobile-max) {
      padding-left: 0;
      display: none;
    }
  }

  .si-home-team {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      width: 6px;
      height: 100%;
      background: rgb(255, 181, 0);
    }
  }
}

//standing rhs
.si-rhs-wrapper {
  width: 100%;
  margin: 0 auto;

  @media screen and (max-width: $media-tablet-max) {
    max-width: 100%;
  }

  .si-stnd-row {
    height: 75px;

    &:nth-child(even) {
      background: #efefef;
    }

    &:last-child {
      border-bottom: 1px solid rgba(0, 64, 140, 0.5);
      @include border-radius(0px);
    }

    @media screen and (max-width: $media-desktop-min) {
      height: 49px;
    }

    @media screen and (max-width: $media-tablet-max) {
      height: 60px;
    }

    &.si-stnd-th {
      @include border-radius(0px);

      @media screen and (max-width: $media-desktop-min) {
        height: 50px;
      }

      @media screen and (max-width: $media-tablet-max) {
        height: 60px;
      }
    }
  }

  .si-stnd-cell {
    font-family: $regular_font-family;
    font-weight: $regular_font-weight;
    font-size: 21px;
    flex-basis: 15%;

    @media screen and (max-width: $media-tablet-max) {
      font-size: 14px !important;
    }

    @media screen and (max-width: $media-mobile-esm-max) {
      font-size: 14px;
    }

    &.si-pos {
      display: none;
    }

    &.si-wlt {
      @include flexbox;
    }

    &.si-tms {
      flex-basis: 20%;
      text-align: center;
      padding-left: 18px;
      font-family: $medium_font-family;
      font-weight: $medium_font-weight;

      @media screen and (max-width: $media-tablet-max) {
        padding-left: 15px;
      }

      .si-qualified {
        left: 20px;
        font-size: 18px;

        @media screen and (max-width: $media-tablet-max) {
          font-size: 16px;
          left: 14px;
        }

        @media screen and (max-width: $media-mobile-max) {
          font-size: 14px;
        }

        @media screen and (max-width: $media-mobile-esm-max) {
          font-size: 12px;
        }
      }
    }

    &.si-net {
      flex-basis: 20%;
    }

    &.si-pts {
      flex-basis: 22%;
      font-family: $medium_font-family;
      font-weight: $medium_font-weight;

      @media screen and (max-width: $media-tablet-max) {
        flex-basis: 15%;
      }
    }

    &.si-wlt {
      display: none;
    }

    // &.si-won,
    // &.si-tie,
    // &.si-nr {
    //   display: none;
    // }
  }

  .si-team-flag {
    display: block;
  }

  .si-team-name {
    padding-left: 0;
    display: none;
    text-transform: uppercase;
  }

  .si-fullName {
    display: none;
  }

  .si-shortName {
    display: block;
  }
}

.hi-page,
.hindi-page {
  .si-standing-wrapper .si-stnd-row.si-stnd-th .si-stnd-cell {
    font-weight: bold;
  }
}