//Colors
$transparent: transparent;
$default: #000000;
$gray: #444444;
$white: #ffffff;
$black: #000000;
$lightGary : #e2e2e2;
//client colors
$client-primary-color:#001848;
$client-secondary-color:#083f88;
$client-complementary-color:#d1ab3e;
$client-complementary-action-color:#ffffff;
$pie-color: #112060;
//Graph Legends Color
$clr-thirdMan: #033416;
$clr-fineLeg: #e67d08;
$clr-squareLeg: #813207;
$clr-midWicket: #151e47;
$clr-midOn: #0a0d2c;
$clr-midOff: #571d45;
$clr-covers: #360727;
$clr-point: #0a4d22;
$client-batting:#fe4a1f;
$client-bowling:#602d91;
$superOver:#00408c;
// social icons
$googlePlus:#dd4b39;
$facebook:#3a589b;
$twitter:#1da1f2;
$whatsApp:#7ad06d;
// $instagram: ;
//Match-Status
$livMat:#001848;
$completedMat:#fff;
$upcomingMat:#fff;
$whitesmoke: #F4F4F4;

//----------------------------------END HERE
//
//Font Family
$regular_font-family:"NoirProRegular",
sans-serif;
$bold_font-family: "NoirProBold",
sans-serif;
$semibold_font-family: "NoirProSemiBold",
sans-serif;
$medium_font-family:"NoirProMedium",
sans-serif;
$light_font-family:"NoirProLight",
sans-serif;

//----------------------------------END HERE
//
//Font Weight
$regular_font-weight: normal;
$bold_font-weight: normal;
$semibold_font-weight: normal;
$medium_font-weight: normal;
$light_font-weight: normal;

//----------------------------------END HERE
//
//Media Breakpoints
$media-mobile-min:320px;
$media-mobile-esm-max: 380px; //extra small mobile
$media-mobile-sm-min: 425px;
$media-mobile-sm-max: 460px; //extra small
$media-mobile-land-sm-min: 639px;
$media-mobile-land-sm-max:660px; //landscape small device
$media-mobile-max: 767px;
$media-tablet-min: 768px;
$media-tablet-max: 992px;
$media-desktop-min: 1024px;
$media-desktop-max: 1200px;
$media-lg-desktop-max: 1440px;
$media-lg-desktop-min: 1280px;
//----------------------------------END HERE
//
//Hidden Class
$web-hidden: none;
$mobile-hidden: none;