@import "common/_variables";
@import "common/_mixins";

/*_ This is to hide scorecard on fixtures page, temporary solution_*/
div[data-wdtype=horizontalWidget] {
  display: none;
}

/**********************************************/

.si-countdwn-wrp {
  background: #083f88;
  margin-left: 20px;
}

.si-coutdown-container {
  padding: 5px 5px 5px 10px;
  min-height: 40px;
  margin: 0 auto;
  align-items: center;
  text-transform: uppercase;
  color: $white;
  position: relative;
  @include flexbox;

  .si-header-wrap {
    flex-basis: auto;
    text-align: right;
    border-bottom: none;
    min-width: auto;
    position: relative;
    padding: 0;
    text-transform: initial;
    padding-right: 10px;

    &:after {
      content: "";
      position: absolute;
      right: 0;
      top: 50%;
      width: 1px;
      height: 60%;
      background: $white;
      @include transform(translateY(-50%));
    }

    span {
      font-size: 13px;
      line-height: 1;
      text-transform: capitalize;
      display: block;
      font-family: $medium_font-family;
      font-weight: $medium_font-weight;
      color: $white;
    }
  }

  .si-next-match {
    margin: 0 10px;
    position: relative;
  }

  .si-next-match,
  .si-timer-wrap {
    @include flexbox;

    .si-team {
      flex-basis: auto;
      width: 30px;
      position: relative;
      @include flexbox;
      align-items: center;
    }

    .si-name {
      font-size: 16px;
      display: none;
      font-family: $bold_font-family;
      font-weight: $bold_font-weight;

      // @media screen and (max-width: $media-mobile-max) {
      //   font-size: 14px;
      // }
    }

    .si-flag {
      display: block;
      width: 30px;
      height: 30px;
      @include background-size(80%);
      background-repeat: no-repeat;
      position: absolute;
      background-position: center center;
      top: 50%;
      @include transform(translateY(-50%));
      background-color: $white;
      border-radius: 5px;
    }
  }

  .si-next-match {
    flex-basis: 35%;
  }

  .si-timer-wrap {
    flex-basis: 22%;
    display: none;
  }

  .si-score-vs {
    font-size: 11px;
    font-family: $bold_font-family;
    font-weight: $bold_font-weight;
    color: $white;
    text-transform: uppercase;
    width: 35px;
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;

    // @media screen and (max-width: $media-mobile-max) {
    //   font-size: 9px;
    // }

    .si-svg-bg {
      display: none;
    }

    svg {
      display: none;
    }

    .si-vs-svg {
      padding: 0;
      margin: 0;
      height: auto;
    }

    .si-vs-txt {
      position: relative;
      // top: 50%;
      // @include transform(translateY(-50%));
      // left: -5px;
      // right: 0;
      text-align: center;
      margin: 0 auto;
      color: $white;
      font-size: 11px;

      // @media screen and (max-width: $media-mobile-max) {
      //   font-size: 9px;
      // }
    }
  }
}

@media screen and (max-width: $media-mobile-max) {
  .si-countdwn-wrp {
    margin-left: 0;
  }

  .si-coutdown-container {
    padding: 5px;
    min-height: 40px;

    .si-header-wrap {
      text-align: left;
      min-width: 52px;

      &:after {
        height: 100%;
      }

      span {
        font-size: 12px;
      }
    }

    .si-next-match {
      //margin: 0 7px;
      margin: 0 4px;
      position: relative;
    }

    .si-next-match,
    .si-timer-wrap {
      .si-team {
        width: 25px;
        height: 25px;
      }

      .si-flag {
        width: 25px;
        height: 25px;
        @include background-size(100%);
        position: relative;
        background-position: center center;
        top: unset;
        @include transform(translateY(0%));
      }

      .si-name {
        font-size: 14px;
      }
    }

    .si-next-match {
      flex-basis: unset;
    }

    .si-timer-wrap {
      flex-basis: 22%;
      display: none;
    }

    .si-score-vs {
      width: 22px;
      font-size: 9px;

      .si-vs-txt {
        position: relative;
        top: unset;
        @include transform(translateY(0%));
        left: unset;
        right: 0;
        font-size: 9px;
      }
    }
  }

  .hi-page,
  .hindi-page {
    .si-coutdown-container .si-score-vs {
      margin: 0 2px;
    }
  }
}

@media screen and (max-width: 320px) {
  .si-coutdown-container {
    flex-wrap: wrap;
    justify-content: center;

    .si-header-wrap {
      &:after {
        display: none;
      }
    }

    .si-next-match {
      margin: 4px 7px 0;
    }
  }
}