@import "common/_variables";
@import "common/_mixins";
@import "common/_proj-common";
@import "common/_custom-icons";
@import "common/_animation";

//@import "common/_swiper.min";
//@import "common/_fonts";

//all widgets css
@import "_wl-countdown.scss";
@import "_wl-fixtures.scss";
@import "_wl-piechart.scss";
@import "_wl-player-profile.scss";
@import "_wl-player-stats.scss";
@import "_wl-squad.scss";
@import "_wl-standings.scss";
@import "_wl-team-stats.scss";
@import "mast-head.scss";

.si-squad-title {
    text-align: center;
    font-weight: 900;
    color: #001848;
  }