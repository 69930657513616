@import "common/_variables";
@import "common/_mixins";

.si-fixtures-container {
  .gender-toggle {
    margin-right: 10px;
    @media screen and (max-width: $media-mobile-max) {
      justify-content: center;
      margin: 0 auto;
    }
  }
  .si-dropdown-contnr {
    margin-bottom: 25px;

    @media screen and (max-width: $media-tablet-max) {
      margin: 0px auto 25px;
    }

    @media screen and (max-width: $media-mobile-max) {
      flex-direction: column;
    }

    .si-dropdown-wrap {
      flex-basis: 50%;

      @media screen and (max-width: $media-mobile-max) {
        flex-basis: 100%;
        width: 100%;
        margin-top: 25px;
        order: 2;
      }
    }

    .si-tab-wrp {
      flex-basis: unset;
      @media screen and (max-width: $media-mobile-max) {
        flex-basis: 100%;
        width: 100%;
      }
    }

    .si-dropdown-header {
      display: none;
    }

    .si-dropdown-wrap {
      li {
        @media screen and (max-width: $media-tablet-max) {
          display: block;
          width: 50%;
        }
      }

      .si-dropdown-header {
        @media screen and (max-width: $media-tablet-max) {
          font-size: 12px;
        }
      }

      .si-selectWrap {
        @media screen and (max-width: $media-tablet-max) {
          width: 100%;
          font-size: 16px;
        }

        @media screen and (max-width: $media-mobile-max) {
          font-size: 20px;
        }

        @media screen and (max-width: $media-mobile-land-sm-min) {
          font-size: 14px;
        }
      }
    }
  }
  .si-first-inn,
  .si-last-inn {
    display: inline-block;
  }
  .si-last-inn {
    position: relative;
    padding-left: 8px;

    @media screen and (max-width: $media-desktop-min) {
      padding-left: 5px;
    }

    @media screen and (max-width: $media-tablet-max) {
      padding-left: 4px;
    }

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      right: auto;
      left: 0;
      width: 1px;
      height: 100%;
      @include linearGradient(top, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.3) 50%, rgba(255, 255, 255, 0) 100%);
      @include transform(translateY(-50%));
    }
  }
}

.si-fixtures-wrapper {
  margin: 0 auto;

  .si-section-wrap {
    background-color: $white;
    position: relative;
    margin-top: 25px;

    &:first-child {
      margin-top: 5px;
    }

    @media screen and (max-width: $media-tablet-max) {
      margin-top: 25px;
    }

    &.si-multimatch-day {
      .si-section-header {
        margin-bottom: -10px;
      }

      .si-section-data {
        margin-top: 10px;
        border: none;
      }
    }
  }

  .si-section-header {
    position: relative;
    border: 1px solid rgba(185, 185, 185, 0.8);
    border-bottom: none;

    @media screen and (max-width: $media-tablet-max) {
      @include flexbox;
      align-items: center;
      border: none;
    }

    .si-header-data {
      font-size: 18px;
      color: $white;
      text-transform: uppercase;
      padding: 0 20px;
      position: relative;
      z-index: 1;
      align-items: center;
      height: 40px;
      line-height: 1;
      background-color: rgba($client-secondary-color, 1);
      font-family: $medium_font-family;
      font-weight: $medium_font-weight;

      @media screen and (max-width: $media-tablet-max) {
        font-size: 20px;
        height: 60px;
        flex-basis: 100%;
        text-align: center;
        padding: 0 10px;
      }

      @media screen and (max-width: $media-mobile-land-sm-min) {
        font-size: 14px;
        height: 30px;
      }
    }

    .si-match-date {
      flex-basis: 50%;

      @media screen and (max-width: $media-tablet-max) {
        flex-basis: 100%;
      }

      span {
        position: relative;
      }

      .si-mat-day {
        font-size: 18px;
        text-transform: capitalize;
        position: relative;
        padding-left: 15px;
        font-family: $light_font-family;
        font-weight: $light_font-weight;

        @media screen and (max-width: $media-tablet-max) {
          font-size: 20px;
        }

        @media screen and (max-width: $media-mobile-land-sm-min) {
          font-size: 14px;
        }

        &:before {
          content: "";
          position: absolute;
          left: 5px;
          right: auto;
          top: 50%;
          width: 1px;
          height: 90%;
          background: $white;
          @include transform(translateY(-50%));
        }
      }

      .si-match {
        @media screen and (max-width: $media-tablet-max) {
          display: block;
        }
      }
    }

    .si-time {
      display: none;
    }

    .si-match-status {
      flex-basis: 50%;
      text-align: right;
      display: none;

      @media screen and (max-width: $media-tablet-max) {
        text-align: right;
      }
    }
  }

  .si-section-data {
    background: #f5f5f5;
    border-top: none;
    position: relative;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    @include flexbox;

    @media screen and (max-width: $media-tablet-max) {
      border: none;
      flex-direction: column;
      padding: 0 15px 20px;
      @include box-shadow(0px 2px 2px 0px rgba(0, 0, 0, 0.19));
    }

    @media screen and (max-width: $media-mobile-max) {
      padding: 0 5px 15px;
    }

    .si-lftwrp {
      flex-basis: 15%;
      width: calc(100% - 50px);
      height: 100%;
      margin: 0 auto;
      padding: 0 25px;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      @include flexbox;
      position: relative;

      @media screen and (max-width: $media-tablet-max) {
        flex-direction: row;
        width: 100%;
        justify-content: center;
      }

      @media screen and (max-width: $media-mobile-max) {
        padding: 0 5px;
      }

      &:after {
        content: "";
        position: absolute;
        left: auto;
        right: 0;
        top: 50%;
        width: 2px;
        height: 100%;
        min-height: 130px;
        @include transform(translateY(-50%));
        @include linearGradient(
          rgba(255, 255, 255, 0) 0%,
          rgba($client-complementary-color, 1) 50%,
          rgba(255, 255, 255, 0) 100%
        );

        @media screen and (max-width: $media-tablet-max) {
          display: none;
        }
      }

      .si-match {
        font-size: 20px;
        padding: 5px 0;
        position: relative;
        text-transform: capitalize;
        font-family: $semibold_font-family;
        font-weight: $semibold_font-weight;

        @media screen and (max-width: $media-tablet-max) {
          padding: 15px 15px 15px 0;
        }

        @media screen and (max-width: $media-mobile-land-sm-min) {
          padding-right: 10px;
        }

        &:after {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 80%;
          height: 1px;
          @include linearGradient(left, rgba($client-primary-color, 1), rgba(0, 0, 0, 0));

          @media screen and (max-width: $media-tablet-max) {
            width: 1px;
            height: 40%;
            top: 50%;
            left: auto;
            right: 0;
            @include transform(translateY(-50%));
            background: $client-primary-color;
          }
        }
      }

      .si-match-status {
        padding: 5px 0;
        font-size: 16px;
        text-transform: capitalize;
        color: $client-primary-color;
        font-family: $regular_font-family;
        font-weight: $regular_font-weight;

        @media screen and (max-width: $media-tablet-max) {
          padding-left: 15px;
          font-size: 20px;
        }

        @media screen and (max-width: $media-mobile-land-sm-min) {
          font-size: 16px;
          padding-left: 20px;
        }
      }
    }

    .si-btn-matcentre {
      @media screen and (max-width: $media-tablet-max) {
        //display: none;
      }
    }

    .si-rgtwrp {
      flex-basis: 25%;
      padding-left: 35px;
      position: relative;

      @media screen and (max-width: $media-desktop-min) {
        padding-left: 15px;
      }

      @media screen and (max-width: $media-tablet-max) {
        flex-basis: 100%;
        width: 100%;
        padding: 15px 0;
      }

      .si-time-venue {
        text-transform: uppercase;
        line-height: 1;
        position: relative;
        justify-content: flex-start;
        flex-direction: column;
        font-family: $semibold_font-family;
        font-weight: $semibold_font-weight;
        color: $client-primary-color;
        @include flexbox;

        @media screen and (max-width: $media-tablet-max) {
          flex-direction: row;
          padding: 8px 0 20px;
          justify-content: center;
          align-items: center;
        }

        span {
          display: block;
        }
      }

      .si-time {
        font-size: 14px;
        font-family: $medium_font-family;
        font-weight: $medium_font-weight;

        @media screen and (max-width: $media-tablet-max) {
          font-size: 16px;
          padding-right: 10px;
        }

        @media screen and (max-width: $media-mobile-land-sm-min) {
          font-size: 12px;
          min-width: 75px;
          text-align: right;
        }
      }

      .si-venue {
        font-size: 14px;
        text-transform: capitalize;
        padding-top: 5px;
        height: 33px;
        font-family: $regular_font-family;
        font-weight: $regular_font-weight;

        @media screen and (max-width: $media-tablet-max) {
          font-size: 16px;
          height: auto;
          padding-left: 10px;
          padding-top: 0;
          position: relative;

          &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            width: 1px;
            height: 100%;
            background: rgba($client-primary-color, 1);
            @include transform(translateY(-50%));
          }
        }

        @media screen and (max-width: $media-mobile-land-sm-min) {
          font-size: 12px;
        }
      }

      .si-btn-wrap {
        justify-content: flex-start;

        @media screen and (max-width: $media-tablet-max) {
          justify-content: center;
        }
      }

      .si-btn-matcentre {
        display: none;

        @media screen and (max-width: $media-tablet-max) {
          display: block;
        }
      }

      .si-action {
        color: $client-secondary-color;
        background: $transparent;
        outline: none;
        margin-left: 20px;
        border: 1px solid $client-secondary-color;

        @media screen and (max-width: $media-desktop-min) {
          margin-left: 5px;
        }

        @media screen and (max-width: $media-tablet-max) {
          margin-left: 0px;
        }

        &.si-btn-matcentre,
        &.si-btn-buytiket {
          border: none;
          color: $client-primary-color;
          background: $client-complementary-color;
        }

        &:first-child {
          margin-left: -5px;

          &.si-btn-buytiket {
            .si-buy-tiket {
              right: auto;
              left: 0;
            }
          }
        }

        &:last-child {
          margin-left: 10px;
        }

        &:nth-child(3) {
          margin-left: 0px;

          @media screen and (max-width: $media-tablet-max) {
            margin-left: 10px;
          }

          @media screen and (max-width: $media-mobile-min) {
            margin-left: 5px;
          }
        }
      }

      .si-btn-common {
        justify-content: center;
        align-items: center;
        height: 100%;
        cursor: pointer;
        @include flexbox;

        @media screen and (max-width: $media-tablet-max) {
          padding: 8px 10px;
        }

        a {
          &:hover {
            color: inherit;
          }
        }
      }
    }

    .si-data-wrapper {
      position: relative;
      flex-basis: 60%;
      padding: 0 25px;

      @media screen and (max-width: $media-desktop-min) {
        padding: 0 10px;
      }

      @media screen and (max-width: $media-tablet-max) {
        position: static;
        padding: 0 5px;
        flex-basis: 100%;
        width: 100%;
      }

      @media screen and (max-width: $media-mobile-land-sm-min) {
        padding: 0;
      }

      &:after {
        content: "";
        position: absolute;
        right: 0;
        left: auto;
        height: 80%;
        width: 1px;
        top: 50%;
        @include linearGradient(top, rgba(255, 255, 255, 0) 0%, rgba($black, 0.3) 50%, rgba(255, 255, 255, 0) 100%);
        @include transform(translateY(-50%));

        @media screen and (max-width: $media-tablet-max) {
          width: 80%;
          height: 1px;
          top: 0;
          margin: 0px auto;
          left: 0;
          right: 0;
        }
      }
    }

    .si-sub-status {
      font-size: 14px;
      text-transform: uppercase;
      position: absolute;
      left: 0;
      right: 0;
      top: 10px;
      margin: 0 auto;
      text-align: center;
      font-family: $bold_font-family;
      font-weight: $bold_font-weight;
      color: $default;

      @media screen and (max-width: $media-tablet-max) {
        font-size: 12px;
        top: 35px;
      }
    }

    .si-match-data {
      padding: 15px 0 1px;

      @media screen and (max-width: $media-tablet-max) {
        padding: 5px 0;
      }
    }

    .si-team-data {
      flex-basis: 45%;
      @include flexbox;
      align-items: center;
      position: relative;
    }

    .si-vs {
      font-size: 12px;
      text-transform: uppercase;
      flex-basis: 15%;
      justify-content: center;
      align-items: center;
      display: flex;
      position: relative;
      font-family: $regular_font-family;
      font-weight: $regular_font-weight;
      color: $white;

      @media screen and (max-width: $media-mobile-land-sm-min) {
        font-size: 10px;
      }
    }

    .si-vs-txt1 {
      padding: 0 10px;
      height: 18px;
      z-index: 1;
      align-items: center;
      background-color: $client-primary-color;
      @include flexbox;

      @media screen and (max-width: $media-mobile-land-sm-min) {
        padding: 0 5px;
        height: 15px;
      }
    }

    .si-score-info {
      text-align: left;
      font-size: 26px;
      font-family: $bold_font-family;
      font-weight: $bold_font-weight;
      font-weight: 700;
      color: $default;
      flex-basis: 25%;

      @media screen and (max-width: $media-desktop-min) {
        font-size: 20px;
      }

      @media screen and (max-width: $media-tablet-max) {
        flex-basis: 40%;
        font-size: 24px;
      }

      @media screen and (max-width: $media-mobile-land-sm-min) {
        font-size: 20px;
        flex-basis: 50%;
        min-width: auto;
      }

      span {
        display: block;
        line-height: 20px;

        @media screen and (max-width: $media-tablet-max) {
          line-height: 16px;
        }
      }

      .si-overs {
        font-size: 13px;
        padding-top: 5px;
        font-family: $regular_font-family;
        font-weight: $regular_font-weight;
        display: block;

        @media screen and (max-width: $media-tablet-max) {
          font-size: 12px;
        }

        @media screen and (max-width: $media-mobile-min) {
          font-size: 10px;
        }

        em {
          padding-left: 5px;
          text-transform: capitalize;
        }
      }

      .si-over {
        font-size: 13px;
        padding-top: 5px;
        font-family: $regular_font-family;
        font-weight: $regular_font-weight;
        display: block;

        @media screen and (max-width: $media-tablet-max) {
          font-size: 12px;
        }

        @media screen and (max-width: $media-mobile-min) {
          font-size: 10px;
        }

        em {
          padding-left: 5px;
          text-transform: capitalize;
        }
      }
    }

    .si-team-lose {
      color: #858585;

      .si-overs {
        color: #858585;
      }

      .si-over {
        color: #858585;
      }
    }

    .si-team-info {
      @include flexbox;
      flex-basis: 75%;
      align-items: center;
      justify-content: flex-end;
      position: relative;

      @media screen and (max-width: $media-tablet-max) {
        flex-basis: 60%;
      }

      @media screen and (max-width: $media-mobile-land-sm-min) {
        flex-basis: 50%;
      }
    }

    .si-team-name {
      font-size: 16px;
      line-height: 1;
      width: 130px;
      text-transform: uppercase;
      font-family: $medium_font-family;
      font-weight: $medium_font-weight;
      color: $client-primary-color;

      @media screen and (max-width: $media-desktop-min) {
        font-size: 14px;
      }

      @media screen and (max-width: $media-mobile-max) {
        width: 115px;
      }

      @media screen and (max-width: $media-mobile-land-sm-min) {
        font-size: 12px;
        width: 95px;
      }

      @media screen and (max-width: $media-mobile-esm-max) {
        width: 85px;
      }

      @media screen and (max-width: $media-mobile-min) {
        font-size: 10px;
      }

      .si-shortName {
        display: none;
      }

      .si-fullName {
        @media screen and (max-width: $media-mobile-max) {
          display: block;
        }
      }
    }

    .si-team-flag {
      display: block;
      width: 65px;
      height: 65px;
      background: no-repeat center center;
      margin-bottom: 10px;
      background-size: contain;

      @media screen and (max-width: $media-tablet-max) {
        width: 55px;
        height: 55px;
      }

      @media screen and (max-width: $media-mobile-esm-max) {
        width: 35px;
        height: 35px;
      }
    }

    .si-teamA {
      .si-team-info {
        padding-right: 35px;

        @media screen and (max-width: $media-desktop-min) {
          padding-right: 15px;
        }

        @media screen and (max-width: $media-tablet-max) {
          padding-right: 5px;
          flex-direction: column-reverse;
        }

        @media screen and (max-width: $media-mobile-max) {
          padding-right: 0;
        }
      }

      .si-team-name {
        padding-right: 25px;
        text-align: right;

        @media screen and (max-width: $media-desktop-min) {
          padding-right: 15px;
        }

        @media screen and (max-width: $media-tablet-max) {
          padding-right: 10px;
          text-align: center;
        }

        @media screen and (max-width: $media-mobile-max) {
          padding-right: 0;
        }
      }

      .si-score-info {
        text-align: right;
      }

      .si-curr-inn {
        .si-score {
          &:after {
            border-right: 8px solid $completedMat;
            right: -15px;

            @media screen and (max-width: $media-tablet-max) {
              border-left: 5px solid $completedMat;
              left: auto;
              right: 60px;
              border-right: none;
            }
          }

          &:before {
            display: none;
          }
        }
      }
    }

    .si-teamB {
      flex-direction: row-reverse;

      .si-team-info {
        padding-left: 25px;
        flex-direction: row-reverse;

        @media screen and (max-width: $media-desktop-min) {
          padding-left: 15px;
        }

        @media screen and (max-width: $media-tablet-max) {
          padding-left: 5px;
          flex-direction: column-reverse;
        }

        @media screen and (max-width: $media-mobile-max) {
          padding-left: 0;
        }
      }

      .si-team-name {
        padding-left: 30px;
        text-align: left;

        @media screen and (max-width: $media-desktop-min) {
          padding-left: 15px;
        }

        @media screen and (max-width: $media-tablet-max) {
          padding-left: 0px;
          text-align: center;
        }
      }

      .si-score-info {
        text-align: left;
      }

      .si-curr-inn {
        .si-score {
          &:before {
            border-left: 8px solid $completedMat;
            left: -15px;

            @media screen and (max-width: $media-mobile-max) {
              border-left: 5px solid $completedMat;
              right: 60px;
              left: auto;
            }
          }

          &:after {
            display: none;
          }
        }
      }
    }

    .si-details {
      position: relative;
      font-size: 14px;
      height: 30px;
      margin: 5px auto 15px;
      line-height: 1;
      padding: 0 15px;
      text-align: center;
      justify-content: center;
      align-items: center;
      font-family: $medium_font-family;
      font-weight: $medium_font-weight;
      color: $client-primary-color;
      @include flexbox;
      @include border-radius(15px);

      @media screen and (max-width: $media-desktop-max) {
        width: 55%;
      }

      @media screen and (max-width: $media-tablet-max) {
        width: 100%;
        font-size: 16px;
      }

      @media screen and (max-width: $media-mobile-land-sm-min) {
        font-size: 12px;
      }
    }

    .si-venue {
      display: none;
    }

    .si-swiper-venue {
      display: none;
    }

    .si-curr-inn {
      .si-score {
        position: relative;

        &:before,
        &:after {
          content: "";
          width: 0;
          height: 0;
          border-top: 8px solid transparent;
          border-bottom: 8px solid transparent;
          position: absolute;
          top: 50%;
          @include transform(translateY(-50%));
          margin: 0 auto;

          @media screen and (max-width: $media-tablet-max) {
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;
          }
        }
      }
    }

    .si-btn-wrap {
      justify-content: center;
      @include flexbox;
    }

    .si-action {
      position: relative;
      font-family: $medium_font-family;
      font-weight: $medium_font-weight;
      font-size: 14px;
      color: $client-primary-color;
      text-align: center;
      background-color: $client-complementary-color;
      width: 130px;
      height: 30px;
      text-transform: capitalize;
      align-items: center;
      justify-content: center;
      @include border-radius(20px);

      @media screen and (max-width: $media-mobile-land-sm-min) {
        font-size: 12px;
      }

      @media screen and (max-width: $media-mobile-min) {
        font-size: 10px;
      }

      a {
        position: relative;
        cursor: pointer;
        @include flexbox;
        align-items: center;
        justify-content: center;
        height: 100%;
      }
    }

    .si-buy-tiket {
      display: none;
      position: absolute;
      top: 50%;
      @include transform(translateY(-50%));
      right: -30px;
      z-index: 1;

      @media screen and (max-width: $media-mobile-max) {
        right: -20px;
      }

      .si-close-btn {
        position: absolute;
        top: 50%;
        @include transform(translateY(-50%));
        right: 12px;
        z-index: 1;
        cursor: pointer;

        &:before {
          content: "\e882";
          font-family: "waf-icon-font";
          font-size: 14px;
          color: $white;

          @media screen and (max-width: $media-mobile-max) {
            font-size: 12px;
          }
        }
      }

      .si-tiket-seller {
        display: block;
        cursor: pointer;
        width: 90px;
        height: 30px;
        background-repeat: no-repeat;
        border: none;
        @include background-size(contain);
        position: relative;

        &:first-child {
          &:before {
            display: none;
          }
        }

        &:nth-child(2) {
          &:before {
            content: "";
            background: $white;
            width: 1px;
            height: 65%;
            position: absolute;
            left: -7px;
            top: 50%;
            @include transform(translateY(-50%));

            @media screen and (max-width: $media-mobile-max) {
              left: -5px;
            }
          }
        }

        @media screen and (max-width: $media-mobile-max) {
          width: 70px;
          height: 24px;
        }
      }

      .si-btn-common {
        background-color: #666666;
        padding: 0 30px 0 0;
        @include border-radius(15px);
        @include flexbox;
      }
    }
  }

  .matCompleted {
    .si-sub-status,
    .si-btn-buytiket {
      display: none;
    }
  }

  .matLive {
    .si-match-status {
      color: $livMat;

      em {
        position: relative;

        &:before {
          content: "";
          background: $livMat;
          width: 8px;
          height: 8px;
          @include border-radius(50%);
          position: absolute;
          left: -15px;
          top: 50%;
          @include transform(translateY(-50%));

          @media screen and (max-width: $media-mobile-max) {
            width: 6px;
            height: 6px;
            left: -10px;
          }
        }
      }
    }
  }

  .matUpcoming {
    .si-team-info {
      flex-basis: 100% !important;
    }

    .si-sub-status {
      display: none;
    }

    .si-venue {
      display: block;
    }

    .si-details {
      margin: 5px auto;
      height: auto;
    }

    .si-lftwrp {
      padding-top: 15px;
    }

    .si-data-wrapper {
      padding: 5px 0 15px;
    }

    .si-match-date,
    .si-match-status {
      flex-basis: 45%;

      @media screen and (max-width: $media-tablet-max) {
        flex-basis: auto;
      }
    }

    .si-time {
      flex-basis: 10%;

      @media screen and (max-width: $media-tablet-max) {
        flex-basis: auto;
      }
    }

    .si-res-stus {
      display: none;
    }

    .si-score-info {
      display: none;
    }

    .si-teamA,
    .si-teamB {
      .si-team-info {
        padding: 0;

        @media screen and (max-width: $media-tablet-max) {
          flex-basis: 100%;
          flex-direction: row;
        }
      }

      .si-team-name {
        @media screen and (max-width: $media-tablet-max) {
          text-align: right;
          padding-right: 15px;
        }

        @media screen and (max-width: $media-mobile-esm-max) {
          padding-right: 10px;
        }
      }
    }

    .si-teamB {
      .si-team-info {
        @media screen and (max-width: $media-tablet-max) {
          flex-direction: row-reverse;
        }
      }

      .si-team-name {
        @media screen and (max-width: $media-tablet-max) {
          text-align: left;
          padding-right: 0;
          padding-left: 15px;
        }

        @media screen and (max-width: $media-mobile-esm-max) {
          padding-left: 10px;
        }
      }
    }

    .si-buy-tiket {
      &.si-active {
        display: block;
      }
    }

    .si-mat-report,
    .si-mat-gal {
      display: none;
    }

    .si-btn-wrap {
      justify-content: center;
      align-items: center;
      @include flexbox;

      .si-action {
        margin: 0 1px;
      }
    }
  }
}

/*_si_fixture_widget_*/
.si-fixture-widget {
  position: relative;
  max-width: inherit;

  &:hover {
    .si-left-arrow,
    .si-right-arrow {
      display: block;
    }
  }

  .si-section-wrap {
    position: relative;
    cursor: pointer;
    margin-top: 0;
    margin-bottom: 25px;
    background: #f2f2f2;

    &:first-child {
      margin-top: 0;
      margin-left: 1px;

      @media screen and (max-width: $media-mobile-max) {
        margin-left: 0;
      }
    }
  }

  .si-section-header {
    align-items: center;
    @include flexbox;

    .si-header-data {
      font-size: 14px;
      height: 40px;
      flex-basis: 100%;
      line-height: 1;
      border: 1px solid $client-primary-color;
      border-bottom: none;
      padding: 0 15px;
      background: $client-primary-color;
      font-family: $regular_font-family;
      font-weight: $regular_font-weight;

      @media screen and (max-width: $media-mobile-max) {
        font-size: 14px;
      }

      @media screen and (max-width: $media-mobile-land-sm-min) {
        font-size: 12px;
      }
    }

    .si-match-date {
      padding-left: 0;

      @media screen and (max-width: $media-tablet-max) {
        text-align: left;
      }

      .si-day {
        font-size: 18px;
        font-family: $bold_font-family;
        font-weight: $bold_font-weight;
      }
    }

    .si-match-status {
      display: block;
      text-align: right;
      padding-left: 0;
      padding-right: 0;
      font-size: 12px;
      font-family: $medium_font-family;
      font-weight: $medium_font-weight;

      em {
        font-style: normal;
      }
    }
  }

  .si-section-data {
    border: 1px solid rgba(185, 185, 185, 1);

    .si-btn-matcentre {
      display: block;
      font-size: 16px;

      @media screen and (max-width: $media-mobile-land-sm-min) {
        font-size: 14px;
      }
    }
  }

  .si-btn-wrap {
    padding-bottom: 10px;
    position: absolute;
    top: auto;
    bottom: -25px;
    margin: 0 auto;
    left: 0;
    right: 0px;
    width: auto;
  }

  .si-action {
    position: relative;
    font-family: $medium_font-family;
    font-weight: $medium_font-weight;
    font-size: 14px;
    margin: 0 auto;
    color: $client-primary-color;
    text-align: center;
    background-color: $client-complementary-color;
    width: 130px;
    height: 30px;
    text-transform: capitalize;
    align-items: center;
    justify-content: center;
    @include border-radius(20px);

    @media screen and (max-width: $media-mobile-land-sm-min) {
      width: 90px;
      font-size: 12px;
    }

    a {
      position: relative;
      cursor: pointer;
      @include flexbox;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }

  .si-buy-tiket {
    position: absolute;
    top: 50%;
    @include transform(translateY(-50%));
    left: auto;
    right: -22px;
    margin: 0 auto;
    z-index: 1;

    .si-tiket-seller {
      width: 70px;
      height: 24px;
    }

    .si-close-btn {
      &:before {
        font-size: 12px;
      }
    }
  }

  .si-section-data {
    display: block;
    padding: 0 25px 20px;

    .si-data-wrapper {
      position: static;
      width: 100%;
      padding: 0;
    }

    .si-sub-status {
      display: none;
    }

    .si-match-data {
      position: relative;
      flex-wrap: wrap;
      padding: 10px 0 0;
    }

    .si-team-data {
      flex-basis: 100%;
    }

    .si-vs {
      flex-basis: 100%;
      justify-content: flex-start;
      padding: 5px 0;
      position: relative;
      margin-left: -25px;

      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        left: 0;
        right: auto;
        @include linearGradient(left, rgba(0, 36, 82, 1) 0%, rgba(0, 36, 82, 0) 100%);
      }
    }

    .si-vs-txt1 {
      padding: 0 20px;
    }

    .si-score-info {
      flex-basis: 40%;
      font-size: 26px;

      @media screen and (max-width: $media-mobile-land-sm-min) {
        font-size: 20px;
      }

      span {
        line-height: 20px;

        @media screen and (max-width: $media-mobile-max) {
          line-height: 16px;
        }
      }

      .si-overs {
        font-size: 16px;
        line-height: 20px;
        display: block;
        padding-top: 5px;
        font-family: "NoirProRegular", sans-serif;
        font-weight: normal;

        @media screen and (max-width: $media-mobile-land-sm-min) {
          font-size: 14px;
        }
      }

      .si-over {
        font-size: 16px;
        line-height: 20px;
        display: block;
        padding-top: 5px;
        font-family: "NoirProRegular", sans-serif;
        font-weight: normal;

        @media screen and (max-width: $media-mobile-land-sm-min) {
          font-size: 14px;
        }
      }
    }

    .si-team-info {
      flex-basis: 60%;
    }

    .si-team-name {
      font-size: 16px;
      padding-left: 10px;
      width: 120px;

      @media screen and (max-width: $media-mobile-land-sm-min) {
        font-size: 14px;
      }
    }

    .si-team-flag {
      width: 60px;
      height: 60px;
      display: block;
      background: no-repeat center center;
      margin-bottom: 10px;
      background-size: contain;
    }

    .si-teamA {
      .si-team-info {
        padding-right: 0;
        flex-direction: row-reverse;
      }

      .si-team-name {
        text-align: left;
        padding-right: 0;
      }

      .si-curr-inn {
        .si-score {
          &:after {
            border-left: 5px solid $completedMat;
            left: 0;
            right: auto;
            border-right: none;
          }
        }
      }
    }

    .si-teamB {
      flex-direction: inherit;

      .si-team-info {
        padding-left: 0;

        @media screen and (max-width: $media-tablet-max) {
          flex-direction: row-reverse;
        }
      }

      .si-team-name {
        padding-left: 10px;

        @media screen and (max-width: $media-tablet-max) {
          text-align: left;
        }
      }

      .si-score-info {
        text-align: right;
      }

      .si-curr-inn {
        .si-score {
          &:before {
            border-left: 5px solid $completedMat;
            left: 0;
            right: auto;
          }
        }
      }
    }

    .si-details {
      width: 90%;
      font-size: 14px;
      margin: 0 auto;
      text-align: center;
      height: 40px;
      @include border-radius(15px);

      @media screen and (max-width: $media-mobile-land-sm-min) {
        font-size: 12px;
        height: 30px;
      }
    }

    .si-curr-inn {
      .si-score {
        &:before,
        &:after {
          border-top: 5px solid transparent;
          border-bottom: 5px solid transparent;
        }
      }
    }
  }

  .matLive {
    em {
      position: relative;

      &:before {
        width: 6px;
        height: 6px;
        left: -10px;
      }
    }

    .si-section-header {
      .si-header-data {
        background: $client-complementary-color;
        border: 1px solid $client-complementary-color;
      }
    }

    .si-match-date {
      color: $client-primary-color;
    }
  }

  .matCompleted {
    .si-match-status {
      color: $completedMat;
    }
  }

  .matUpcoming {
    .si-match-status {
      flex-basis: 40%;

      @media screen and (max-width: $media-mobile-max) {
        flex-basis: 35%;
      }
    }

    .si-match-date {
      flex-basis: 60%;

      @media screen and (max-width: $media-mobile-max) {
        flex-basis: 50%;
      }
    }

    .si-match-status {
      display: none;
    }

    .si-time {
      flex-basis: 20%;
      text-align: right;
      flex: 1;
      display: block;

      @media screen and (max-width: $media-mobile-max) {
        flex-basis: 30%;
      }
    }

    &.si-section-wrap {
      cursor: inherit;
    }
  }

  .si-fullName {
    display: block;
  }

  .si-left-arrow,
  .si-right-arrow {
    width: 35px;
    height: 35px;
    background-color: $client-complementary-color;
    @include border-radius(50%);
    position: absolute;
    top: 50%;
    cursor: pointer;
    z-index: 3;
    @include transform(translateY(-50%));
    display: none;

    &:before {
      font-family: "dataviz-icon-fonts" !important;
      font-size: 18px;
      font-weight: 700;
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      @include transform(translateY(-50%));
      text-align: center;
      color: $client-primary-color;

      @media screen and (max-width: $media-mobile-max) {
        font-size: 16px;
      }
    }

    &.swiper-button-disabled {
      &:before {
        opacity: 0.3;
        cursor: not-allowed;
      }
    }
  }

  .si-left-arrow {
    left: 10px;

    &:before {
      content: "\e900";
      @include transform(rotate(180deg) translateY(50%));
    }
  }

  .si-right-arrow {
    right: 10px;
    left: auto;

    &:before {
      content: "\e900";
    }
  }

  .si-yetbat {
    font-size: 12px;
  }
}

/*_si_fixture_rhs_*/

.si-fixture-rhs {
  max-width: 377px;
  margin: 0 auto;

  @media screen and (max-width: $media-tablet-max) {
    max-width: 100%;
  }

  &:before,
  &:after {
    display: none;
  }

  &:hover {
    &:before,
    &:after {
      display: none;
    }
  }

  .si-left-arrow,
  .si-right-arrow {
    display: none;
  }

  .si-section-header {
    .si-match {
      display: block;
    }
  }

  .si-section-data {
    .si-vs {
      padding: 15px 0;
    }

    .si-details {
      font-size: 14px;

      @media screen and (max-width: $media-mobile-max) {
        font-size: 11px;
      }
    }
  }

  .swiper-pagination {
    display: none;
  }

  .swiper-pagination-bullet-active {
    background: #fb2515;
  }

  .si-yetbat {
    font-size: 12px;
  }
}

// for fixture page super over provision
.si-fixtures-wrapper {
  .si-section-data {
    .si-team-data {
      &.si-super-over {
        .si-score-info {
          flex-basis: 40%;

          @media screen and (max-width: $media-desktop-min) {
            flex-basis: 50%;
          }

          @media screen and (max-width: $media-tablet-max) {
            flex-basis: 53%;
          }
        }

        .si-team-info {
          flex-basis: 60%;

          @media screen and (max-width: $media-desktop-min) {
            flex-basis: 50%;
          }

          @media screen and (max-width: $media-tablet-max) {
            flex-basis: 47%;
          }
        }
      }
    }
  }
}

.si-fixture-widget {
  .swiper-wrapper {
    display: flex;

    .swiper-slide {
      width: 436px;
      margin-right: 10px;

      @media screen and (max-width: $media-mobile-max) {
        margin-right: 0px;
        width: 100%;
      }
    }
  }

  .si-section-data {
    .si-team-data {
      &.si-super-over {
        .si-score-info {
          flex-basis: 50%;
          font-size: 18px;

          @media screen and (max-width: $media-desktop-min) {
            flex-basis: 60%;
            font-size: 16px;
          }

          @media screen and (max-width: $media-mobile-max) {
            flex-basis: 50%;
          }
        }

        .si-team-info {
          flex-basis: 50%;

          @media screen and (max-width: $media-desktop-min) {
            flex-basis: 40%;
          }

          @media screen and (max-width: $media-mobile-max) {
            flex-basis: 50%;
          }
        }

        .si-team-name {
          @media screen and (max-width: $media-desktop-min) {
            padding-left: 10px;
          }
        }
      }
    }
  }

  .matUpcoming {
  }

  a {
    &.si-no-clickable {
      cursor: default !important;
      background: #aba7a7;
      border-radius: 20px;
      color: #fff;
    }
  }
}
.mr-page,
.marathi-page {
  .si-fixture-widget {
    .si-section-data {
      .si-details {
        line-height: 1.25;
      }
    }
  }
}

.hi-page,
.hindi-page {
  .si-fixture-widget {
    .si-team-name {
      font-size: 17px;
    }
  }
}
.fixtures-page {
  .si-single-match {
    &.matLive {
      display: block;
    }
  }
}
.si-single-match {
  &.matLive {
    display: none;
  }
}

.si-over-text {
  display: none;
}

.si-fixtures-home {
  .si-over-text {
    display: inline-block;
  }
}

.si-fixtures-home {
  &.si-over-text {
    display: block;
  }
}

/*_ Hindi Pages _*/

.hi-page,
.hindi-page {
  .si-fixtures-wrapper .si-section-data .si-team-name,
  .si-fixtures-wrapper .si-section-data .si-details,
  .si-fixtures-wrapper .si-section-data .si-lftwrp .si-match,
  .si-fixtures-wrapper .si-section-header .si-header-data,
  .si-fixtures-wrapper .si-section-data .si-action,
  .si-fixtures-wrapper .si-section-data .si-details {
    font-weight: bold;
  }

  .si-fixtures-wrapper .si-section-data .si-team-name {
    line-height: 1.4;
  }
}
