@import "common/_variables";
@import "common/_mixins";
.si-teamstats-container {
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  margin: 0 auto;
  .si-header-wrap {
    justify-content: flex-end;
    .si-dropdown-contnr {
      width: 100%;
      position: relative;
      flex-direction: column;
      .si-dropdown-wrap {
        @media screen and (max-width: $media-tablet-max) {
        }
        @media screen and (max-width: $media-mobile-max) {
        }
        .si-selectWrap {
          font-size: 18px;
          width: 170px;
          height: 45px;
          text-transform: capitalize;
          border: 1px solid $client-primary-color;
          @include border-radius(50px);
          @media screen and (max-width: $media-tablet-max) {
            height: 40px;
            font-size: 15px;
          }
          @media screen and (max-width: $media-mobile-max) {
            width: 180px;
            font-size: 14px;
          }
          @media screen and (max-width: $media-mobile-esm-max) {
            width: 140px;
            height: 30px;
            font-size: 12px;
          }
        }
        .selectActive {
          padding: 0 15px;
        }
        ul {
          @media screen and (max-width: $media-mobile-max) {
            justify-content: flex-end;
            flex-basis: 40%;
          }
        }
      }
      .si-tab-wrp {
        width: 40%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-basis: auto;
        line-height: 1;
        @media screen and (max-width: $media-desktop-min) {
          width: 35%;
        }
        @media screen and (max-width: $media-mobile-max) {
          width: 100%;
          order: 1;
        }
        .si-inrwrp {
          width: 100%;
          .si-tabs {
            font-size: 18px;
            height: 45px;
            width: 50%;
            cursor: pointer;
            @media screen and (max-width: $media-tablet-max) {
              font-size: 15px;
              height: 40px;
            }
            @media screen and (max-width: $media-mobile-max) {
              width: 100%;
              font-size: 11px;
              height: 30px;
            }
          }
        }
      }
    }
    .si-dropdown-wrap {
      margin-top: 15px;
    }
  }
  .si-stats-fixtures-wrapper {
    min-height: 600px;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    @include flexbox;
    @media screen and (max-width: $media-tablet-max) {
      min-height: 500px;
    }
    @media screen and (max-width: $media-mobile-max) {
      min-height: 400px;
    }
    .si-section-data {
      background: transparent;
      position: relative;
      width: 100%;
      height: 100%;
      padding: 30px 0px;
      border-bottom: 1px solid #d3d3d3;
      @media screen and (max-width: $media-mobile-max) {
        padding: 30px 0px 20px;
      }
      &:first-child {
        @media screen and (max-width: $media-mobile-max) {
          padding: 25px 0px 20px;
        }
      }
    }
    .si-match-data {
      min-height: 100px;
      margin: 0 40px;
      position: relative;
      align-items: center;
      justify-content: space-between;
      @include flexbox;
      @media screen and (max-width: $media-mobile-max) {
        padding: 0px;
        margin: 0px;
      }
    }
    .si-team-data {
      width: 130px;
      min-height: 100px;
      position: absolute;
      top: 0px;
      flex-shrink: 0;
      overflow: hidden;
      text-align: center;
      flex-direction: column;
      @include flexbox;
      @media screen and (max-width: $media-mobile-max) {
        width: 80px;
        min-height: 90px;
        justify-content: space-around;
      }
    }
    .si-vs {
      width: calc(100% - 260px);
      margin: 0 auto;
      @media screen and (max-width: $media-mobile-max) {
        width: 100%;
      }
    }
    .si-stadium-wrp {
      color: #6a6a6a;
      font-size: 12px;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      line-height: 1;
      margin-bottom: 15px;
      @include flexbox;
      @media screen and (max-width: $media-tablet-max) {
        font-size: 10px;
      }
      @media screen and (max-width: $media-mobile-max) {
        font-size: 10px;
        width: 80%;
        margin: 0 auto;
      }
      @media screen and (max-width: $media-mobile-esm-max) {
        width: 60%;
      }
    }
    .si-stadium-icon {
      margin-right: 5px;
      position: relative;
      text-align: center;
      &:after {
        content: "\e94e";
        font-family: "dataviz-icon-fonts" !important;
        font-size: 20px;
        color: #6a6a6a;
        @media screen and (max-width: $media-mobile-max) {
          font-size: 15px;
        }
      }
    }
    .si-progress-wrp {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 12px;
      background-color: $client-complementary-color;
      @include border-radius(100px);
      @media screen and (max-width: $media-mobile-max) {
        height: 8px;
        margin: 32px 0px;
      }
    }
    .si-score-progress {
      position: absolute;
      background-color: $client-secondary-color;
      height: 12px;
      @include transition(width, 2s, ease-in-out);
      @media screen and (max-width: $media-mobile-max) {
        height: 8px;
      }
    }
    .si-home-team {
      .si-score-progress {
        left: 0px;
        background-color: $client-secondary-color;
      }
    }
    .si-away-team {
      .si-score-progress {
        right: 0px;
        background-color: #164889;
      }
    }
    .si-match-result {
      margin-top: 15px;
      color: $client-primary-color;
      font-size: 14px;
      padding: 0 5px;
      text-transform: uppercase;
      text-align: center;
      @media screen and (max-width: $media-tablet-max) {
        font-size: 12px;
      }
      @media screen and (max-width: $media-mobile-max) {
        font-size: 11px;
        margin-top: 0px;
      }
    }
    .si-show-more {
      width: 100%;
      padding: 30px 0px;
      align-items: center;
      justify-content: flex-end;
      color: $client-primary-color;
      font-size: 18px;
      font-family: $medium_font-family;
      font-weight: $medium_font-weight;
      @include flexbox;
      @media screen and (max-width: $media-tablet-max) {
        font-size: 16px;
      }
      @media screen and (max-width: $media-mobile-max) {
        font-size: 14px;
      }

      .si-show-more-text {
        cursor: pointer;
        padding-right: 25px;
        position: relative;
        align-items: center;
        justify-content: center;
        @include flexbox;
        @media screen and (max-width: $media-mobile-max) {
          padding-right: 20px;
        }
        &:after {
          font-family: "dataviz-icon-fonts" !important;
          position: absolute;
          right: 0px;
          top: 5px;
          font-size: 15px;
          color: $client-primary-color;
          @media screen and (max-width: $media-tablet-max) {
            font-size: 13px;
          }
          @media screen and (max-width: $media-mobile-max) {
            font-size: 11px;
          }
        }
      }
      .si-show-less-text {
        cursor: pointer;
        padding-right: 23px;
        position: relative;
        align-items: center;
        justify-content: center;
        @include flexbox;
        @media screen and (max-width: $media-mobile-max) {
          padding-right: 15px;
        }
      }
      &.si-load-more {
        .si-show-more-text {
          &:after {
            content: "\e904";
            font-family: "dataviz-icon-fonts" !important;
            position: absolute;
            right: 0px;
            top: 5px;
            font-size: 15px;
            color: $client-primary-color;
            @media screen and (max-width: $media-tablet-max) {
              font-size: 13px;
            }
            @media screen and (max-width: $media-mobile-max) {
              font-size: 11px;
            }
          }
        }
      }
      &.si-load-less {
        .si-show-less-text {
          &:after {
            content: "\e904";
            font-family: "dataviz-icon-fonts" !important;
            position: absolute;
            right: 0px;
            top: 5px;
            font-size: 15px;
            color: $client-primary-color;
            transform: rotate(180deg);
            @media screen and (max-width: $media-tablet-max) {
              font-size: 13px;
            }
            @media screen and (max-width: $media-mobile-max) {
              font-size: 11px;
            }
          }
        }
      }
    }
    .si-score-info {
      font-size: 20px;
      font-family: $bold_font-family;
      font-weight: $semibold_font-weight;
      color: $client-primary-color;
      @media screen and (max-width: $media-tablet-max) {
        font-size: 18px;
      }
      @media screen and (max-width: $media-mobile-max) {
        font-size: 12px;
      }
      span {
        display: block;
        line-height: 1;
      }
      .si-overs {
        font-size: 13px;
        padding-top: 5px;
        font-family: $regular_font-family;
        font-weight: $regular_font-weight;
        @media screen and (max-width: $media-tablet-max) {
          font-size: 12px;
        }
        @media screen and (max-width: $media-mobile-min) {
          font-size: 10px;
        }
        em {
          padding-left: 5px;
          text-transform: capitalize;
        }
      }
    }
    .si-team-lose {
      font-family: $regular_font-family;
      font-weight: $regular_font-weight;
      color: $client-primary-color;
      .si-overs {
        color: #858585;
      }
    }
    .si-team-info {
      align-items: center;
      justify-content: flex-end;
      flex-direction: column-reverse;
      position: relative;
      // margin-bottom: 5px;
      @include flexbox;
      @media screen and (max-width: $media-mobile-max) {
        margin-bottom: 0;
        /*margin-top: -5px;*/
      }
    }
    .si-team-name {
      font-size: 20px;
      line-height: 1;
      text-transform: uppercase;
      font-family: $medium_font-family;
      font-weight: $medium_font-weight;
      text-align: center;
      color: $client-primary-color;
      @media screen and (max-width: $media-tablet-max) {
        font-size: 18px;
      }
      @media screen and (max-width: $media-mobile-max) {
        font-size: 12px;
      }
      .si-shortName {
        display: inline-block;
      }
      .si-fullName {
        display: none;
      }
    }
    .si-flag-wrap {
      height: 45px;
      justify-content: center;
      align-items: center;
      @include flexbox;
      @media screen and (max-width: $media-mobile-max) {
        height: 35px;
      }
    }
    .si-team-flag {
      display: block;
      width: 40px;
      height: 40px;
      margin-bottom: 5px;
      @include background-size(contain);
      background-repeat: no-repeat;
      @media screen and (max-width: $media-tablet-max) {
        width: 50px;
        height: 35px;
      }
      @media screen and (max-width: $media-mobile-max) {
        width: 35px;
        height: 30px;
        margin-bottom: 0px;
        background-repeat: no-repeat;
      }
    }
    .si-teamA {
      left: 0px;
      .si-team-info {
        @media screen and (max-width: $media-mobile-max) {
          flex-direction: row-reverse;
        }
      }
      .si-team-name {
        @media screen and (max-width: $media-mobile-max) {
          margin-left: 5px;
        }
      }
      .si-score-info {
        .si-first-inn {
          .si-score {
            @media screen and (max-width: $media-mobile-max) {
              text-align: left;
            }
          }
        }
      }
      .si-curr-inn {
        .si-score {
          &:after {
            border-right: 8px solid $completedMat;
            right: -15px;
            @media screen and (max-width: $media-mobile-max) {
              border-left: 5px solid $completedMat;
              left: auto;
              right: 60px;
              border-right: none;
            }
          }
          &:before {
            display: none;
          }
        }
      }
    }
    .si-teamB {
      right: 0px;
      .si-team-info {
        @media screen and (max-width: $media-mobile-max) {
          flex-direction: row;
        }
      }
      .si-team-name {
        @media screen and (max-width: $media-mobile-max) {
          margin-right: 5px;
        }
      }
      .si-score-info {
        .si-first-inn {
          .si-score {
            @media screen and (max-width: $media-mobile-max) {
              text-align: right;
            }
          }
        }
      }
      .si-curr-inn {
        .si-score {
          &:before {
            border-left: 8px solid $completedMat;
            left: -15px;
            @media screen and (max-width: $media-mobile-max) {
              border-left: 5px solid $completedMat;
              right: 60px;
              left: auto;
            }
          }
          &:after {
            display: none;
          }
        }
      }
    }
  }
  .si-stats-wrapper {
    .si-sec-wrap {
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      color: $client-primary-color;
      font-size: 30px;
      font-family: $medium_font-family;
      font-weight: $medium_font-weight;
      @include flexbox;
      @media screen and (max-width: $media-tablet-max) {
        font-size: 26px;
      }
      @media screen and (max-width: $media-mobile-max) {
        font-size: 15px;
        margin-bottom: 10px;
        justify-content: flex-start;
      }
    }
  }
  .si-stats-con {
    line-height: 1;
    @include linearGradient(
      to right,
      rgba(255, 255, 255, 0.1),
      rgba(245, 245, 245, 1),
      rgba(255, 255, 255, 0.1)
    );
    @media screen and (max-width: $media-mobile-max) {
      background-color: #f6f5f5;
      padding: 10px;
    }
  }
  .si-ply {
    width: 100%;
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: wrap;
    @include flexbox;
    &--listing {
      flex-basis: 33%;
      padding: 40px;
      position: relative;
      font-family: $medium_font-family;
      font-weight: $medium_font-weight;
      @media screen and (max-width: $media-tablet-max) {
        padding: 20px;
      }
      @media screen and (max-width: $media-mobile-max) {
        flex-basis: 50%;
        padding: 15px 10px;
      }
      @media screen and (max-width: $media-mobile-esm-max) {
        padding: 10px 5px;
      }
      &:before {
        content: "";
        position: absolute;
        left: 0px;
        top: 0px;
        background-color: #d3d3d3;
        width: 1px;
        height: 100%;
        top: 50%;
        @include transform(translateY(-50%));
        @media screen and (min-width: $media-tablet-min) {
          height: 50%;
        }
      }
      &:after {
        content: "";
        position: absolute;
        left: 0px;
        right: 0px;
        bottom: 0px;
        background-color: #d3d3d3;
        width: 100%;
        height: 1px;
        @media screen and (max-width: $media-mobile-max) {
          left: 50%;
          transform: translateX(-50%);
          width: 100%;
        }
      }
      &:nth-child(1),
      &:nth-child(4) {
        &:before {
          @media screen and (min-width: $media-tablet-min) {
            background-color: transparent;
          }
        }
        &:after {
          @media screen and (min-width: $media-tablet-min) {
            width: 85%;
            right: 0px;
            left: inherit;
          }
          @media screen and (min-width: $media-desktop-min) {
            width: 73%;
            right: 0px;
            left: inherit;
          }
        }
      }
      &:nth-child(3),
      &:nth-child(6) {
        &:after {
          @media screen and (min-width: $media-tablet-min) {
            width: 85%;
            right: inherit;
          }
          @media screen and (min-width: $media-desktop-min) {
            width: 73%;
            right: inherit;
          }
        }
      }
      &:nth-child(1),
      &:nth-child(3),
      &:nth-child(5) {
        &:before {
          @media screen and (max-width: $media-mobile-max) {
            background-color: transparent;
          }
        }
      }
    }
    &--box {
      width: 80%;
      margin: 0 auto;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      @include flexbox;
      @media screen and (max-width: $media-mobile-max) {
        width: 85%;
      }
    }
    &--status {
      display: block;
      margin-bottom: 10px;
      padding-left: 20px;
      text-align: left;
      color: #001747;
      font-size: 15px;
      @media screen and (max-width: $media-tablet-max) {
        font-size: 14px;
      }
      @media screen and (max-width: $media-mobile-max) {
        font-size: 10px;
        margin-bottom: 5px;
      }
      @media screen and (max-width: $media-mobile-sm-max) {
        padding-left: 10%;
      }
    }
    &--infowrp {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      @include flexbox;
    }
    &--imgwrp {
      background-color: #ececec;
      width: 100px;
      height: 100px;
      overflow: hidden;
      margin-right: 10px;
      flex-shrink: 0;
      @include border-radius(100px);
      @media screen and (max-width: $media-tablet-max) {
        width: 70px;
        height: 70px;
      }
      @media screen and (max-width: $media-mobile-max) {
        width: 45px;
        height: 45px;
        margin-right: 5px;
      }
    }
    &--det {
      text-align: left;
      flex-basis: 90%;
    }
    &--img {
      display: block;
      margin: 10px auto 0;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center top;
      background-size: cover;
    }
    &--score {
      color: #001549;
      font-size: 20px;
      @media screen and (max-width: $media-tablet-max) {
        font-size: 18px;
      }
      @media screen and (max-width: $media-mobile-max) {
        font-size: 9px;
      }
    }
    &--snum {
      font-size: 40px;
      @media screen and (max-width: $media-tablet-max) {
        font-size: 28px;
      }
      @media screen and (max-width: $media-mobile-max) {
        font-size: 19px;
      }
    }
    &--name {
      color: #001747;
      font-size: 18px;
      @media screen and (max-width: $media-tablet-max) {
        font-size: 14px;
      }
      @media screen and (max-width: $media-mobile-max) {
        font-size: 12px;
      }
      @media screen and (max-width: $media-mobile-sm-max) {
        font-size: 10px;
      }
      @media screen and (max-width: $media-mobile-min) {
        font-size: 8px;
      }
    }
  }
  .si-os {
    max-width: 700px;
    width: 700px;
    margin: 0 auto;
    padding: 40px 0px;

    @media screen and (max-width: $media-tablet-max) {
      max-width: 100%;
      width: 100%;
      padding: 0px;
    }
    @media screen and (max-width: $media-tablet-max) {
      background: rgba(#d3d3d3, 0.2);
    }
    &--wrp {
      width: 100%;
      align-items: center;
      justify-content: flex-start;
      flex-direction: row;
      @include flexbox;
      position: relative;
    }
    &--col {
      width: 100%;
      padding: 0 25px;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;
      @include flexbox;
      @media screen and (max-width: $media-mobile-max) {
        padding: 0px;
      }
      &:before {
        content: "";
        position: absolute;
        right: 0px;
        margin: 0 auto;
        bottom: 0px;
        background-color: #d3d3d3;
        width: 1px;
        height: 100%;
      }
      &:last-child {
        &:before {
          display: none;
        }
      }
    }
    &--data {
      width: 100%;
      color: #001747;
      font-family: $medium_font-family;
      font-weight: $medium_font-weight;
      text-align: center;
      position: relative;
      padding: 20px 0;
      @media screen and (max-width: $media-mobile-max) {
        padding: 15px 0;
      }
      &:first-child {
        &:after {
          content: "";
          position: absolute;
          left: 0px;
          right: 0px;
          bottom: 0px;
          background-color: #d3d3d3;
          width: 100%;
          height: 1px;
          @media screen and (max-width: $media-mobile-max) {
            left: 50%;
            transform: translateX(-50%);
            width: 70%;
          }
        }
      }
    }
    &--title {
      font-size: 15px;
      height: 20px;
      justify-content: center;
      align-items: center;
      @include flexbox;
      @media screen and (max-width: $media-mobile-max) {
        font-size: 10px;
      }
    }
    &--scr {
      font-size: 40px;
      height: 50px;
      justify-content: center;
      align-items: center;
      @include flexbox;
      @media screen and (max-width: $media-tablet-max) {
        font-size: 30px;
      }
      @media screen and (max-width: $media-mobile-max) {
        font-size: 19px;
        height: auto;
      }
    }
  }
  .si-shortName {
    display: inline-block;
  }
}

@keyframes progress {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
