@import "common/_variables";
@import "common/_mixins";

.si-plyr-profile-cntnr {
  position: relative;

  .top-bg {
    width: 100%;
    height: 425px;
    background-color: #083f88;
    position: relative;

    @media screen and (max-width: $media-mobile-land-sm-min) {
      height: 250px;
    }

    &:before,
    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }

    &:before {
      top: 50%;
      background: url(../images/ply-prfle-top-bg.jpg) no-repeat top center;
      @include transform(translateY(-50%));
      @include background-size(cover);
    }

    &:after {
      @include linearGradient(rgba(0, 0, 0, 0), rgba(0, 21, 73, 0.8));
      height: 200px;
      bottom: 0;
      top: auto;
    }
  }

  .top-cntnr {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: auto;
    z-index: 1;
    height: 425px;
    overflow: hidden;

    @media screen and (max-width: $media-mobile-land-sm-min) {
      height: 250px;
    }

    .si-profile-wrp {
      height: 100%;
      text-align: center;

      @media screen and (max-width: $media-mobile-max) {
        text-align: left;
      }
    }

    .si-plyrimg {
      height: 430px;
      z-index: 1;
      margin: 0 auto;
      background-repeat: no-repeat;
      background-position: center bottom;
      background-size: contain;
      text-align: center;

      @media screen and (max-width: $media-tablet-max) {
        height: 400px;
        margin-top: 15px;
      }

      @media screen and (max-width: $media-mobile-max) {
        background-position: left bottom;
        text-align: left;
      }

      @media screen and (max-width: $media-mobile-land-sm-min) {
        height: 250px;
        text-align: left;
      }

      @media screen and (max-width: $media-mobile-esm-max) {
        height: 200px;
        background-position: left bottom;
        text-align: left;

      }
    }

    .si-content-wrp {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 1;
      padding-top: 100px;
      justify-content: space-between;
      align-items: flex-start;
      @include flexbox;
      color: $white;

      @media screen and (max-width: $media-mobile-max) {
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-start;
        padding-top: 40px;
      }

      @media screen and (max-width: $media-mobile-land-sm-min) {
        padding-top: 20px;
      }

      @media screen and (max-width: $media-mobile-esm-max) {
        padding-top: 40px;
        padding-right: 5px;
      }
    }

    .si-plyr-dtls-left,
    .si-plyr-dtls-rgt {
      width: 30%;

      @media screen and (max-width: $media-tablet-max) {
        width: 25%;
      }

      @media screen and (max-width: $media-mobile-max) {
        width: calc(100% - 63%);
      }

      .si-inr-wrp {
        width: 220px;

        @media screen and (max-width: $media-tablet-max) {
          width: 150px;
        }

        @media screen and (max-width: $media-mobile-max) {
          width: 100%;
        }
      }
    }

    .si-plyr-dtls-left {
      align-items: center;
      @include flexbox;
      justify-content: flex-end;

      .si-inr-wrp {
        text-align: left;
      }
    }

    .si-plyr-dtls-rgt {
      @media screen and (max-width: $media-mobile-max) {
        justify-content: flex-start;
        padding-top: 30px;
      }

      @media screen and (max-width: $media-mobile-land-sm-min) {
        padding-top: 10px;
      }

      .si-inr-wrp {
        text-align: right;

        @media screen and (max-width: $media-mobile-max) {
          text-align: left;
        }
      }
    }

    .si-rnk-nm-info {
      .si-plyrank {
        font-size: 20px;
        position: relative;
        padding: 5px 0;
        font-family: $regular_font-family;
        font-weight: $regular_font-weight;

        @media screen and (max-width: $media-mobile-max) {
          font-style: 16px;
        }

        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          width: 30px;
          left: 0;
          right: auto;
          height: 1px;
          background: $white;
        }
      }

      .si-plyr-firstnm,
      .si-plyr-lastnm {
        text-transform: uppercase;
        line-height: 1;

        .si-shortName {
          display: none;

          @media screen and (max-width: $media-mobile-max) {
            display: none;
          }
        }

        .si-fullName {
          @media screen and (max-width: $media-mobile-max) {
            display: block;
          }
        }
      }

      .si-plyr-firstnm {
        font-size: 40px;
        text-transform: uppercase;
        color: $client-complementary-color;
        font-family: $bold_font-family;
        font-weight: $bold_font-weight;

        @media screen and (max-width: $media-tablet-max) {
          font-size: 32px;
        }

        @media screen and (max-width: $media-mobile-land-sm-min) {
          font-size: 20px;
        }

        @media screen and (max-width: $media-mobile-sm-min) {
          font-size: 18px;
        }

        @media screen and (max-width: $media-mobile-esm-max) {
          font-size: 16px;
        }
      }

      .si-plyr-lastnm {
        font-size: 25px;
        text-transform: uppercase;
        font-family: $light_font-family;
        font-weight: $light_font-weight;

        @media screen and (max-width: $media-tablet-max) {
          font-size: 20px;
        }

        @media screen and (max-width: $media-mobile-land-sm-min) {
          font-size: 16px;
        }

        @media screen and (max-width: $media-mobile-sm-min) {
          font-size: 14px;
        }

        @media screen and (max-width: $media-mobile-esm-max) {
          font-size: 12px;
        }
      }
    }

    .si-plyr-data {
      margin-bottom: 40px;

      @media screen and (max-width: $media-mobile-max) {
        margin-bottom: 25px;
      }

      @media screen and (max-width: $media-mobile-land-sm-min) {
        margin-bottom: 15px;
      }

      @media screen and (max-width: $media-mobile-sm-max) {
        margin-bottom: 8px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    .si-title {
      font-size: 14px;
      text-transform: uppercase;
      color: $white;
      font-family: $regular_font-family;
      font-weight: $regular_font-weight;

      @media screen and (max-width: $media-mobile-esm-max) {
        font-size: 12px;
      }
    }

    .si-data {
      font-size: 16px;
      text-transform: uppercase;
      line-height: 1;
      color: $client-complementary-color;
      font-family: $bold_font-family;
      font-weight: $bold_font-weight;

      @media screen and (max-width: $media-mobile-land-sm-min) {
        font-size: 14px;
      }

      @media screen and (max-width: $media-mobile-sm-min) {
        font-size: 11px;
      }
    }
  }

  .si-tab-cntnr {
    width: 40%;
    margin: -20px auto 0;
    z-index: 1;
    position: relative;

    @media screen and (max-width: $media-tablet-max) {
      width: 60%;
    }

    @media screen and (max-width: $media-mobile-land-sm-min) {
      width: 80%;
    }

    .si-tab-inr {
      justify-content: center;
      align-items: center;
      height: 40px;
      background: $client-secondary-color;
      @include border-radius(20px);
      @include flexbox;
    }

    .si-tablist {
      flex: 1;
      height: 100%;
      font-size: 18px;
      cursor: pointer;
      font-family: $regular_font-family;
      font-weight: $regular_font-weight;
      background: $client-secondary-color;
      color: $white;
      @include border-radius(20px);

      @media screen and (max-width: $media-mobile-land-sm-min) {
        font-size: 14px;
      }

      span {
        justify-content: center;
        align-items: center;
        height: 100%;
        @include flexbox;
      }

      &.si-active {
        color: $client-primary-color;
        background: $client-complementary-color;
      }
    }
  }

  .si-bio-cntnr {
    width: 60%;
    margin: 0 auto;
    padding: 50px 0 10px;
    line-height: 28px;
    font-size: 18px;
    color: $client-primary-color;
    font-family: $medium_font-family;
    font-weight: $medium_font-weight;

    @media screen and (max-width: $media-tablet-max) {
      width: 80%;
    }

    @media screen and (max-width: $media-mobile-land-sm-min) {
      width: 90%;
      line-height: 22px;
      font-size: 14px;
      padding: 30px 0 10px;
    }

    .si-heading {
      text-transform: capitalize;
      font-size: 25px;
      padding-bottom: 15px;

      @media screen and (max-width: $media-mobile-land-sm-min) {
        font-size: 18px;
      }
    }

    .si-subdtls {
      justify-content: flex-start;
      align-items: center;
      @include flexbox;
    }

    .si-leftwrp,
    .si-rightwrp {
      flex-basis: auto;
    }

    .si-rprsnt-team-info {
      padding: 20px 0;
      line-height: 22px;
    }

    .si-rprsnt-team {
      font-size: 18px;
      color: $client-secondary-color;

      @media screen and (max-width: $media-mobile-land-sm-min) {
        font-size: 14px;
      }
    }
  }

  .si-social-cntnr {
    .si-heading {
      font-size: 16px;
      padding: 0 0 20px 0;
      text-transform: capitalize;
      text-align: center;
      position: relative;
      color: $client-primary-color;
      font-family: $medium_font-family;
      font-weight: $medium_font-weight;

      @media screen and (max-width: $media-mobile-max) {
        padding-top: 20px;
        font-size: 18px;
      }

      @media screen and (max-width: $media-mobile-max) {
        font-size: 14px;
        padding: 10px 0;

        &:after {
          content: "";
          position: absolute;
          width: 80%;
          height: 1px;
          left: 0;
          right: 0;
          margin: 0px auto;
          bottom: 0;
          background: rgba($black, 0.2);
        }
      }
    }

    .si-socialwrp {
      width: 50%;
      margin: 0 auto;
      justify-content: space-between;
      align-items: center;
      @include flexbox;

      @media screen and (max-width: $media-tablet-max) {
        width: 100%;
        flex-wrap: wrap;
      }
    }

    .si-social-icon {
      height: 40px;
      @include flexbox;
      justify-content: center;
      align-items: center;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        font-size: 18px;
        color: $client-secondary-color;
        font-family: "dataviz-icon-fonts" !important;
      }
    }

    .si-social-tab {
      flex: 1;
      padding: 0 35px;
      text-align: center;
      position: relative;
      font-size: 14px;
      color: $client-primary-color;
      font-family: $medium_font-family;
      font-weight: $medium_font-weight;

      @media screen and (max-width: $media-mobile-max) {
        padding: 10px;
        font-size: 12px;
        width: 100%;
      }

      &:after {
        content: "";
        position: absolute;
        left: auto;
        right: 0;
        width: 1px;
        height: 80%;
        top: 50%;
        background: rgba($black, 0.2);
        @include transform(translateY(-50%));

        @media screen and (max-width: $media-mobile-max) {
          display: none;
        }
      }

      &:last-child {
        &:after {
          display: none;
        }
      }

      &.si-fb {
        .si-social-icon {
          &:after {
            background: url(../images/fb.png?v=1.0) center no-repeat;
            width: 13px;
            height: 22px;
            margin: 0px auto;
          }
        }
      }

      &.si-insta {
        .si-social-icon {
          &:after {
            background: url(../images/insta.png?v=1.0) center no-repeat;
            width: 20px;
            height: 19px;
            margin: 0px auto;
          }
        }
      }

      &.si-tweet {
        .si-social-icon {
          &:after {
            background: url(../images/tweet.png?v=1.1) center no-repeat;
            width: 25px;
            height: 21px;
            margin: 0px auto;
            background-size: contain;
            filter: invert(20%) sepia(55%) saturate(2294%) hue-rotate(198deg) brightness(88%) contrast(102%);
          }
        }
      }
    }
  }

  .si-pro-stats-cntnr {
    position: relative;
    @include linearGradient(rgba(0, 0, 0, 0), rgba(242, 242, 242, 1));

    .si-headsec {
      padding: 30px 0 10px;
    }

    .si-headicon {
      border: 1px solid rgb(149, 149, 149);
      position: relative;
      margin: 0px auto;
      width: 95px;
      height: 95px;
      @include border-radius(50px);

      &:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        margin: 0px auto;
        height: 63px;
        width: 69px;
        background: url(../images/headicon.png) no-repeat center center;
        @include transform(translateY(-50%));
        @include background-size(contain);
      }
    }

    .si-head-title {
      font-size: 24px;
      text-align: center;
      text-transform: uppercase;
      padding-top: 8px;
      color: $client-primary-color;
      font-family: $medium_font-family;
      font-weight: $medium_font-weight;
    }

    .si-contentsec {
      width: 100%;
      height: 260px;
      margin: 0 auto;
      justify-content: center;
      align-items: center;
      @include flexbox;
      border-bottom: 2px solid $client-complementary-color;

      @media screen and (max-width: $media-mobile-max) {
        height: auto;
        align-items: flex-start;
        padding: 0 30px;
      }

      @media screen and (max-width: $media-mobile-land-sm-min) {
        padding: 0 10px;
      }
    }

    .si-leftsec,
    .si-rgtsec {
      position: relative;
      flex-basis: 50%;
      text-align: center;
      text-transform: uppercase;
      color: $client-primary-color;
      font-family: $medium_font-family;
      font-weight: $medium_font-weight;

      @media screen and (max-width: $media-desktop-min) {
        flex-basis: 50%;
      }
    }

    .si-rgtsec {
      &:before {
        content: "";
        position: absolute;
        left: 0;
        right: auto;
        width: 1px;
        height: 200px;
        top: 50%;
        @include transform(translateY(-50%));
        background: rgba($black, 0.2);

        @media screen and (max-width: $media-mobile-max) {
          display: none;
        }
      }

      .si-highest {
        position: relative;

        @media screen and (max-width: $media-mobile-max) {
          &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            width: 1px;
            height: 80%;
            margin: 0 auto;
            background: rgba($black, 0.2);
            @include transform(translateY(-50%));
          }
        }
      }
    }

    .si-inr-row {
      @include flexbox;
      justify-content: space-between;
      align-items: center;

      @media screen and (max-width: $media-mobile-max) {
        flex-direction: column;
      }
    }

    .si-col {
      flex: 1;
      width: 100%;
    }

    .si-data {
      font-size: 32px;

      @media screen and (max-width: $media-desktop-min) {
        font-size: 28px;
      }

      @media screen and (max-width: $media-mobile-max) {
        font-size: 40px;
      }

      @media screen and (max-width: $media-mobile-land-sm-min) {
        font-size: 28px;
      }
    }

    .si-title {
      font-size: 14px;

      @media screen and (max-width: $media-desktop-min) {
        font-size: 12px;
      }

      @media screen and (max-width: $media-mobile-max) {
        font-size: 18px;
      }

      @media screen and (max-width: $media-mobile-land-sm-min) {
        font-size: 12px;
      }
    }

    .si-icons {
      position: relative;
      height: 90px;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        margin: 0px auto;
        @include transform(translateY(-50%));
      }
    }

    .si-strike-rt,
    .si-avg-rt {
      height: 60px;
    }

    .si-strike-rt {
      .si-title {
        @media screen and (max-width: $media-mobile-min) {
          font-size: 10px;
        }
      }
    }

    .si-strike-rt,
    .si-avg-rt,
    .si-matplyed,
    .si-highscore,
    .si-total-wkts,
    .si-innings,
    .si-maidens,
    .si-centneco {
      @include flexbox;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    .si-centneco {
      @media screen and (max-width: $media-mobile-max) {
        order: 4;
        width: 100%;
        height: 130px;
      }
    }

    .si-rate {
      .si-icons {
        &:before {
          background: url(../images/strikert.png) center no-repeat;
          width: 100%;
          height: 90px;
          @include background-size(contain);
        }
      }
    }

    .si-matplyed,
    .si-highscore,
    .si-innings,
    .si-maidens {
      height: 90px;

      @media screen and (max-width: $media-mobile-max) {
        width: 100%;
        height: 80px;
      }

      sup {
        vertical-align: text-top;
      }
    }

    .si-highscore,
    .si-maidens {
      position: relative;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        width: 80%;
        height: 1px;
        margin: 0 auto;
        background: rgba($black, 0.2);

        @media screen and (max-width: $media-mobile-max) {
          width: 60%;
        }
      }
    }

    .si-total-scr {
      @media screen and (max-width: $media-mobile-land-sm-min) {
        padding: 0 10px;
      }

      .si-data {
        font-size: 44px;

        @media screen and (max-width: $media-desktop-min) {
          font-size: 30px;
        }

        @media screen and (max-width: $media-mobile-max) {
          font-size: 54px;
        }

        @media screen and (max-width: $media-mobile-land-sm-min) {
          font-size: 28px;
        }
      }

      .si-title {
        font-size: 12px;

        @media screen and (max-width: $media-desktop-min) {
          font-size: 12px;
        }

        @media screen and (max-width: $media-mobile-max) {
          font-size: 18px;
        }

        @media screen and (max-width: $media-mobile-land-sm-min) {
          font-size: 12px;
        }
      }

      .si-icons {
        width: 100%;
        height: 88px;
        margin: 10px auto;

        @media screen and (max-width: $media-mobile-max) {
          width: 40%;
          margin: 0;
        }

        &:before {
          width: 100%;
          height: 90px;
        }
      }
    }

    .si-runscored,
    .si-total-wkts,
    .si-highest,
    .si-rate {
      @media screen and (max-width: $media-mobile-max) {
        @include flexbox;
        height: 160px;
        justify-content: center;
        align-items: center;
        position: relative;

        &:after {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 1px;
          background: rgba($black, 0.2);
        }
      }

      @media screen and (max-width: $media-mobile-land-sm-min) {
        height: 110px;
      }
    }

    .si-highest {
      .si-data {
        font-size: 30px;

        @media screen and (max-width: $media-desktop-min) {
          font-size: 28px;
        }

        @media screen and (max-width: $media-mobile-max) {
          font-size: 32px;
        }

        @media screen and (max-width: $media-mobile-land-sm-min) {
          font-size: 24px;
        }
      }

      @media screen and (max-width: $media-mobile-max) {
        order: 2;
        height: 250px;
        @include flexbox;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }

      @media screen and (max-width: $media-mobile-land-sm-min) {
        height: 200px;
      }
    }

    .si-rate {
      @media screen and (max-width: $media-mobile-max) {
        display: inline-block;
        padding: 20px 0;
        height: 250px;
        order: 3;
      }

      .si-title {
        @media screen and (max-width: $media-mobile-max) {
          position: absolute;
          left: calc(100% - 60px);
          right: 0;
          width: 120px;
          text-align: center;
        }
      }
    }

    .si-centneco {
      @media screen and (max-width: $media-mobile-max) {
        order: 4;
      }
    }

    .si-leftsec {
      .si-centneco {
        align-items: flex-end;

        @media screen and (max-width: $media-mobile-max) {
          align-items: center;
        }
      }

      .si-total-scr {
        .si-icons {
          &:before {
            background: url(../images/runscore.png) no-repeat center;

            @media screen and (max-width: $media-mobile-land-sm-min) {
              @include background-size(70%);
            }
          }
        }

        @media screen and (max-width: $media-mobile-max) {
          order: 1;
          flex-direction: row;
        }
      }
    }

    .si-rgtsec {
      .si-centneco {
        align-items: flex-start;

        @media screen and (max-width: $media-mobile-max) {
          align-items: center;
        }
      }

      .si-rate {
        @media screen and (max-width: $media-mobile-max) {
          position: relative;

          &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            width: 1px;
            height: 40%;
            margin: 0 auto;
            background: rgba($black, 0.2);
            @include transform(translateY(-50%));
          }
        }

        .si-title {
          @media screen and (max-width: $media-mobile-max) {
            display: none;
          }
        }

        .si-icons {
          &:before {
            background: url(../images/bowlstrike.png) center no-repeat;
            width: 100%;
            height: 90px;
            @include background-size(contain);
          }
        }
      }

      .si-total-scr {
        .si-icons {
          &:before {
            background: url(../images/wkts.png) center no-repeat;

            @media screen and (max-width: $media-mobile-land-sm-min) {
              @include background-size(70%);
            }
          }
        }

        @media screen and (max-width: $media-mobile-max) {
          flex-direction: row-reverse;

          &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            width: 1px;
            height: 60%;
            margin: 0 auto;
            background: rgba($black, 0.2);
            @include transform(translateY(-50%));
          }
        }
      }

      .si-centneco {
        @media screen and (max-width: $media-mobile-max) {
          position: relative;

          &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            width: 1px;
            height: 40%;
            margin: 0 auto;
            background: rgba($black, 0.2);
            @include transform(translateY(-50%));
          }
        }
      }
    }
  }
}

/*_ Marathi & Hindi Page _*/
.mr-page,
.marathi-page,
.hi-page,
.hindi-page {

  .si-plyr-profile-cntnr .top-cntnr .si-rnk-nm-info .si-plyr-firstnm,
  .si-plyr-profile-cntnr .top-cntnr .si-data,
  .si-plyr-profile-cntnr .si-bio-cntnr .si-heading,
  .si-plyr-profile-cntnr .si-social-cntnr .si-heading {
    font-weight: bold;
  }

  .si-plyr-profile-cntnr .top-cntnr .si-rnk-nm-info .si-plyr-firstnm,
  .si-plyr-profile-cntnr .top-cntnr .si-rnk-nm-info .si-plyr-lastnm,
  .si-plyr-profile-cntnr .top-cntnr .si-data {
    line-height: 1.2;
  }
}