.si-common-class,
.si-main-wrap {
  position: relative;
  font-family: $regular_font-family;
  font-weight: $regular_font-weight;

  a {
    text-decoration: none;
    color: inherit;
    @include transition(0.3s, all, ease-in-out);

    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
    }

    &:focus {
      background: transparent;
    }
  }

  em {
    font-style: normal;
  }

  ul {
    padding: 0;
    margin: 0;

    li {
      list-style: none;
    }
  }

  em {
    font-style: normal;
  }

  :hover,
  :focus {
    outline: none;
  }

  input:focus,
  textarea:focus {
    @include placeholder {
      color: $transparent;
      outline: none;
    }
  }

  b,
  strong {
    font-family: $bold_font-family;
    font-weight: $bold_font-weight;
  }

  .preloader {
    position: absolute;
    z-index: 1;
  }

  .swiper-pagination-bullets-dynamic {
    margin: 0 auto;
  }
}

.si-shortName {
  display: none;

  @media screen and (max-width: $media-mobile-max) {
    display: block;
  }
}

.si-fullName {
  @media screen and (max-width: $media-mobile-max) {
    display: none;
  }
}

.si-mob {
  display: none;

  @media screen and (max-width: $media-mobile-max) {
    display: block;
  }
}

.si-web {
  @media screen and (max-width: $media-mobile-max) {
    display: none;
  }
}

.bodyNoScroll {
  overflow: hidden;
}

.si-flexBox {
  @extend %flexbox;
}

.si-no-data {
  font-size: 16px;
  align-items: center;
  justify-content: center;
  font-family: $semibold_font-family;
  font-weight: $semibold_font-weight;
  color: $client-primary-color;
  @include flexbox;

  @media screen and (min-width: $media-tablet-min) {
    font-size: 24px;
  }
}

.si-flexBox-reverse-row {
  flex-direction: row-reverse;
}

//table style start here
.si-tbl {
  display: table;
  width: 100%;

  .si-tHead,
  .si-tBody,
  .si-tFoot {
    display: table-header-group;
  }

  .si-tRow {
    display: table-row;
  }

  .si-tCel {
    display: table-cell;
    vertical-align: middle;
  }
}

//jspane scroll CSS
.si-common-class .jspContainer {
  overflow: hidden;
  position: relative;
}

.si-common-class .jspPane {
  position: absolute;
}

.si-common-class .jspVerticalBar {
  position: absolute;
  top: 0;
  right: 0;
  width: 5px;
  height: 100%;
  background: #dddfde;
  transition: 1s;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -ms-transition: 1s;
  -o-transition: 1s;
}

.si-common-class .jspHorizontalBar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 8px;
  background: #dddfde;
  @include transform(transition(1s));
}

.si-common-class .jspCap {
  display: none;
}

.si-common-class .jspCapTop,
.si-common-class .jspCapBottom {
  display: block;
  height: 5px;
  width: 5px;
  display: none;
}

.si-common-class .jspHorizontalBar .jspCap {
  float: left;
}

.si-common-class .jspTrack {
  background: #dddfde;
  position: relative;
}

.si-common-class .jspDrag {
  background: $client-primary-color;
  position: relative;
  top: 0;
  left: 0;
  cursor: pointer; // @include border-radius(5px);
}

.si-common-class .jspHorizontalBar .jspTrack,
.si-common-class .jspHorizontalBar .jspDrag {
  float: left;
  height: 100%;
}

.si-common-class .jspArrow {
  background: #50506d;
  text-indent: -20000px;
  display: block;
  cursor: pointer;
  padding: 0;
  margin: 0;
}

.si-common-class .jspArrow.jspDisabled {
  cursor: default;
  background: #80808d;
}

.si-common-class .jspVerticalBar .jspArrow {
  height: 16px;
}

.si-common-class .jspHorizontalBar .jspArrow {
  width: 16px;
  float: left;
  height: 100%;
}

.si-common-class .jspVerticalBar .jspArrow:focus {
  outline: none;
}

.si-common-class .jspCorner {
  background: #eeeef4;
  float: left;
  height: 100%;
}

* html .jspCorner {
  margin: 0 -3px 0 0;
}

.si-nodata {
  font-family: $regular_font-family;
  font-weight: $regular_font-weight;
  font-size: 24px;
  text-transform: uppercase;
  height: 50px;
  align-items: center;
  justify-content: center;
  @include flexbox;

  @media screen and (max-width: $media-tablet-max) {
    font-size: 18px;
  }

  @media screen and (max-width: $media-mobile-esm-max) {
    font-size: 14px;
  }
}

.si-header-wrap {
  @include flexbox;
  justify-content: space-between;
  align-items: center;
  border-bottom: none;
  font-family: $medium_font-family;
  font-weight: $medium_font-weight;
  text-transform: capitalize;
  font-size: 34px;
  color: $client-primary-color;
  padding: 15px 0;

  @media screen and (max-width: $media-mobile-esm-max) {
    font-size: 24px;
  }
}

.si-dropdown-contnr {
  justify-content: space-between;
  align-items: center;
  @include flexbox;

  .si-tab-wrp {
    flex-basis: 50%;

    @media screen and (max-width: $media-mobile-max) {
      flex-basis: 100%;
      width: 100%;
      order: 1;
    }

    .si-inrwrp {
      justify-content: flex-end;
      align-items: center;
      @include flexbox;
    }

    .si-tabs {
      width: 180px;
      height: 30px;
      font-size: 14px;
      text-align: center;
      text-transform: uppercase;
      font-family: $regular_font-family;
      font-weight: $regular_font-weight;
      border-bottom: 2px solid $client-primary-color;

      @media screen and (max-width: $media-tablet-max) {
        height: 40px;
        font-size: 16px;
        width: 140px;
      }

      @media screen and (max-width: $media-mobile-max) {
        width: 100%;
        font-size: 20px;
      }

      @media screen and (max-width: $media-mobile-esm-max) {
        font-size: 14px;
      }

      span {
        justify-content: center;
        align-items: center;
        @include flexbox;
        height: 100%;
      }

      &.si-active {
        font-family: $medium_font-family;
        font-weight: $medium_font-weight;
        color: $client-complementary-color;
        border-bottom: 5px solid $client-complementary-color;
      }
    }
  }
}

.si-dropdown-wrap {
  ul {
    justify-content: flex-start;
  }

  li {
    @include flexbox;
    align-items: center;
    padding-right: 20px;

    &:last-child {
      padding-right: 0;
    }
  }

  .si-dropdown-header {
    text-transform: uppercase;
    font-size: 16px;
    font-family: $bold_font-family;
    font-weight: $bold_font-weight;
    color: $client-primary-color;
    padding-right: 10px;
  }

  .si-selectWrap {
    font-size: 14px;
    position: relative;
    overflow: hidden;
    width: 180px;
    text-transform: uppercase;
    height: 30px;
    color: $client-primary-color;
    font-family: $regular_font-family;
    font-weight: $regular_font-weight;
    border-bottom: 1px solid $client-primary-color;

    @media screen and (max-width: $media-tablet-max) {
      height: 40px;
    }

    @media screen and (max-width: $media-mobile-max) {
      width: 120px;
    }

    &:after {
      content: "\e904";
      font-family: "dataviz-icon-fonts" !important;
      font-size: 14px;
      position: absolute;
      top: 50%;
      right: 10px;
      left: auto;
      @include transform(translateY(-50%));
      text-align: center;
      color: $client-primary-color;
      width: 18px;
      height: 18px;
      @include border-radius(100%);
      @include flexbox;
      justify-content: center;
      align-items: center;
    }
  }

  .selectActive {
    position: absolute;
    left: 0;
    top: 50%;
    @include transform(translateY(-50%));
    width: 100%;
    padding: 2px 8px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 90%;
  }

  .si-selectBox {
    display: block;
    width: 100%;
    height: 100%;
    z-index: 1;
    position: relative;
    opacity: 0;
    cursor: pointer;
  }
}

.hi-page,
.hindi-page {

  .si-dropdown-contnr .si-tab-wrp .si-tabs.si-active,
  .si-common-class b,
  .si-common-class strong,
  .si-main-wrap b,
  .si-main-wrap strong {
    font-weight: bold;
  }
}