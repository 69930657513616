//--BORDER RADIUS--
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  -o-border-radius: $radius;
  border-radius: $radius;
}

//@include border-radius(10px);
//----------------------------------END HERE
//
//--TRANSFORM--
@mixin transform($transform) {
  -webkit-transform: $transform;
  -moz-transform: $transform;
  -ms-transform: $transform;
  -o-transform: $transform;
  transform: $transform;
}

//@include transform(translateX(50%) translateY(50%) skewX(10deg));
//----------------------------------END HERE
//
//--BACKGROUND SIZE--
@mixin background-size($background-size) {
  -webkit-background-size: $background-size;
  -moz-background-size: $background-size;
  -ms-background-size: $background-size;
  -o-background-size: $background-size;
  background-size: $background-size;
}

//@include background-size(cover);
//----------------------------------END HERE
//

@mixin box-shadow($value) {
    -webkit-box-shadow: $value;
    -moz-box-shadow: $value;
    -ms-box-shadow: $value;
    -o-box-shadow: $value;
    box-shadow: $value;
}
//@include box-shadow(0 0 5px 5px black);
//@include box-shadow(inset 1px 1px 1px blue);
//----------------------------------END HERE
//

//--text SHAODOW--
@mixin text-shadow($value) {
    -webkit-text-shadow: $value;
    -moz-text-shadow: $value;
    -ms-text-shadow: $value;
    -o-text-shadow: $value;
    text-shadow: $value;
}
//@include text-shadow(0 0 5px black);
//----------------------------------END HERE

//--BACKGROUND LINEAR GRADIENT--
@mixin linearGradient($gradientValues...) {
  background: -webkit-linear-gradient($gradientValues);
  background: -moz-linear-gradient($gradientValues);
  background: -ms-linear-gradient($gradientValues);
  background: -o-linear-gradient($gradientValues);
  background: linear-gradient($gradientValues);
}

//@include linearGradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, .5));
//@include linearGradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%);
//----------------------------------END HERE

//
//--BACKGROUND radial GRADIENT--
@mixin radial-gradient($gradientValues...) {
  background: -webkit-radial-gradient($gradientValues);
  background: -moz-radial-gradient($gradientValues);
  background: -ms-radial-gradient($gradientValues);
  background: -o-radial-gradient($gradientValues);
  background: radial-gradient($gradientValues);
}

//@include radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
//----------------------------------END HERE

//
//--BOX SIZING--
@mixin box-sizing($box-model) {
  -webkit-box-sizing: $box-model;
  -moz-box-sizing: $box-model;
  -ms-box-sizing: $box-model;
  -o-box-sizing: $box-model;
  box-sizing: $box-model;
}

//@include box-sizing(border-box);
//----------------------------------END HERE
//
//--TEXT OVERFLOW (ellipsis)--
@mixin text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

//@include text-truncate;
//----------------------------------END HERE
//
//--TRANSITION--
@mixin transition($transition-property, $transition-time, $method) {
  -webkit-transition: $transition-property $transition-time $method;
  -moz-transition: $transition-property $transition-time $method;
  -ms-transition: $transition-property $transition-time $method;
  -o-transition: $transition-property $transition-time $method;
  transition: $transition-property $transition-time $method;
}

//@include transition($transition-property, $transition-time, $method);
//@include transition(padding, 1s, ease-in-out);
//----------------------------------END HERE
//
//--FLEX BOX--
@mixin flexbox {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

%flexbox {
  @include flexbox;
}

//----------------------------------END HERE
//
//--INPUT PLACEHOLDER--
@mixin placeholder {
  $placeholders: ":-webkit-input" ":-moz" "-moz" "-ms-input";
  @each $placeholder in $placeholders {
    &:#{$placeholder}-placeholder {
      @content;
    }
  }
}

//@include placeholder {color: $transparent;}
//
//----------------------------------END HERE
//
//--ANIMATION TRANSITION--
@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

// @include keyframes(slide-down) {
//     0% {
//         opacity: 1;
//         background: red;
//     }
//     100% {
//         opacity: 0;
//         background: blue;
//     }
// }
// .element {
//   width: 100px;
//   height: 100px;
//   background: black;
//   @include animation('slide-down 5s 3');
// }
//
//----------------------------------END HERE
