@font-face {
  font-family: 'dataviz-icon-fonts';
  src:  url('../fonts/dataviz-icon-fonts.eot?kf2prf');
  src:  url('../fonts/dataviz-icon-fonts.eot?kf2prf#iefix') format('embedded-opentype'),
    url('../fonts/dataviz-icon-fonts.ttf?kf2prf') format('truetype'),
    url('../fonts/dataviz-icon-fonts.woff?kf2prf') format('woff'),
    url('../fonts/dataviz-icon-fonts.svg?kf2prf#dataviz-icon-fonts') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="siicon-"], [class*=" siicon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'dataviz-icon-fonts' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.siicon-arrowLeft:before {
  content: "\e900";
}
.siicon-backArrow:before {
  content: "\e901";
}
.siicon-away:before {
  content: "\e902";
}
.siicon-burger:before {
  content: "\e903";
}
.siicon-arrowDown:before {
  content: "\e904";
}
.siicon-fixtures:before {
  content: "\e905";
}
.siicon-home:before {
  content: "\e907";
}
.siicon-latestUpdates:before {
  content: "\e908";
}
.siicon-mitv:before {
  content: "\e90a";
}
.siicon-moreWatch:before {
  content: "\e90b";
}
.siicon-notification:before {
  content: "\e90c";
}
.siicon-photo:before {
  content: "\e90d";
}
.siicon-search:before {
  content: "\e90e";
}
.siicon-squad:before {
  content: "\e90f";
}
.siicon-totalRunScore:before {
  content: "\e910";
}
.siicon-totalWicketTaken:before {
  content: "\e911";
}
.siicon-Average:before {
  content: "\e906";
}
.siicon-Strike-Rate:before {
  content: "\e909";
}
.siicon-Total-wickets:before {
  content: "\e912";
}
.siicon-Run-Score:before {
  content: "\e913";
}
.siicon-Facbook:before {
  content: "\e914";
}
.siicon-Twitter:before {
  content: "\e915";
}
.siicon-instagram:before {
  content: "\e916";
}
.siicon-homevenue:before {
  content: "\e94e";
}
.siicon-add:before {
  content: "\e929";
}
.siicon-close:before {
  content: "\e92a";
}
.siicon-stump:before {
  content: "\e933";
}
